import { render, staticRenderFns } from "./ContainerHeader2v.vue?vue&type=template&id=3303da2a&scoped=true&"
import script from "./ContainerHeader2v.vue?vue&type=script&lang=js&"
export * from "./ContainerHeader2v.vue?vue&type=script&lang=js&"
import style0 from "./ContainerHeader2v.vue?vue&type=style&index=0&id=3303da2a&prod&lang=scss&scoped=true&"
import style1 from "./ContainerHeader2v.vue?vue&type=style&index=1&id=3303da2a&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3303da2a",
  null
  
)

/* custom blocks */
import block0 from "./ContainerHeader2v.vue?vue&type=custom&index=0&blockType=i18n&locale=en-us&lang=yaml"
if (typeof block0 === 'function') block0(component)
import block1 from "./ContainerHeader2v.vue?vue&type=custom&index=1&blockType=i18n&locale=ja&lang=yaml"
if (typeof block1 === 'function') block1(component)

export default component.exports
import {QHeader,QToolbar,QBtnDropdown,QMenu,QList,QItem,QItemSection,QIcon,QBtn,QSpace,QItemLabel,QDialog,QCard,QCardSection,QSeparator,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QHeader,QToolbar,QBtnDropdown,QMenu,QList,QItem,QItemSection,QIcon,QBtn,QSpace,QItemLabel,QDialog,QCard,QCardSection,QSeparator})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
