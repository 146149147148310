import moment from 'moment';
import differenceInDays from 'date-fns/differenceInDays';
import { addDays, addWeeks, getDay, startOfWeek } from 'date-fns';
import { Notify } from 'quasar';
import store from '../store';

export const getDefaultDate = (
  selectedPeriodType: string,
  comparedPeriodType: string
) => {
  let date = {
    startingPeriod: '',
    endingPeriod: '',
    startingCompared: '',
    endingCompared: ''
  };
  try {
    date.startingPeriod = getStartingDate(selectedPeriodType);

    date.endingPeriod = getEndingDate(selectedPeriodType);
    if (comparedPeriodType == 'PREVIOUS_PERIOD') {
      const diffDays = differenceInDays(
        new Date(date.startingPeriod),
        new Date(date.endingPeriod)
      );
      const diffDaysPositive =
        diffDays < 0 ? (diffDays - 1) * -1 : diffDays + 1;
      const weeks = Math.ceil(Number((diffDaysPositive / 7).toFixed(2)));
      const reduceType = getReduceType(comparedPeriodType);
      date.startingCompared = moment(new Date(date.startingPeriod))
        .subtract(weeks, 'week')
        .format('YYYY-MM-DD');
      date.endingCompared = moment(new Date(date.endingPeriod))
        .subtract(weeks, 'week')
        .format('YYYY-MM-DD');
    } else if (comparedPeriodType === 'PREVIOUS_YEAR_SAME_PERIOD') {
      date.startingCompared = moment(new Date(date.startingPeriod))
        .subtract(1, 'year')
        .format('YYYY-MM-DD');
      date.endingCompared = moment(new Date(date.endingPeriod))
        .subtract(1, 'year')
        .format('YYYY-MM-DD');
    } else if (comparedPeriodType === 'PREVIOUS_YEAR_SAME_DAY_OF_WEEK') {
      date.startingCompared = moment(new Date(date.startingPeriod))
        .subtract(364, 'day')
        .format('YYYY-MM-DD');
      date.endingCompared = moment(new Date(date.endingPeriod))
        .subtract(364, 'day')
        .format('YYYY-MM-DD');
    } else {
      date.startingCompared = getStartingDate(comparedPeriodType);
      date.endingCompared = getEndingDate(comparedPeriodType);
    }
  } catch (e) {
    Notify.create({
      type: 'negative',
      message: e,
      position: 'top'
    });
  }
  return date;
};
export const getReduceType = val => {
  if (val === 'TODAY') {
    return 'day';
  }
  if (val === 'YESTERDAY') {
    return 'day';
  }
  if (val === 'THIS_WEEK') {
    return 'week';
  }
  if (val === 'LAST_WEEK') {
    return 'week';
  }
  if (val === 'THIS_MONTH') {
    return 'month';
  }
  if (val === 'LAST_MONTH') {
    return 'month';
  }
  if (val === 'THIS_YEAR') {
    return 'year';
  }
  if (val === 'LAST_YEAR') {
    return 'year';
  }
};

export const getStartingDate = val => {
  let date: any;
  if (val === 'TODAY') {
    date = moment(new Date()).format('YYYY-MM-DD');
  }
  if (val === 'YESTERDAY') {
    date = moment(new Date())
      .subtract(1, 'day')
      .format('YYYY-MM-DD');
  }
  if (val === 'THIS_WEEK') {
    const d = new Date();
    const tempDate =
      d.getDay() - store.state['user'].startOfWeek < 0
        ? addWeeks(d, -1)
        : new Date();
    if (getDay(new Date()) + 1 === store.state['user'].startOfWeek) {
      date = moment(new Date()).format('YYYY-MM-DD');
    } else {
      date = moment(
        addDays(startOfWeek(tempDate), store.state['user'].startOfWeek - 1)
      ).format('YYYY-MM-DD');
    }
    // date = moment(new Date())
    //   .startOf('week')
    //   .format('YYYY-MM-DD');
  }
  if (val === 'LAST_WEEK') {
    const d = new Date();
    const tempDate =
      d.getDay() - store.state['user'].startOfWeek < 0
        ? addWeeks(d, -1)
        : new Date();

    date = moment(
      addDays(startOfWeek(tempDate), store.state['user'].startOfWeek - 1)
    );

    date = moment(date)
      .subtract(1, 'week')
      .format('YYYY-MM-DD');
  }
  if (val === 'THIS_MONTH') {
    date = moment(new Date())
      .startOf('month')
      .format('YYYY-MM-DD');
  }
  if (val === 'LAST_MONTH') {
    date = moment(new Date())
      .startOf('month')
      .subtract(1, 'month')
      .format('YYYY-MM-DD');
  }
  if (val === 'THIS_YEAR') {
    date = moment(new Date())
      .startOf('year')
      .format('YYYY-MM-DD');
  }
  if (val === 'LAST_YEAR') {
    date = moment(new Date())
      .startOf('year')
      .subtract(1, 'year')
      .format('YYYY-MM-DD');
  }
  return date;
};

export const getEndingDate = val => {
  let date: any;
  if (val === 'TODAY') {
    date = moment(new Date()).format('YYYY-MM-DD');
  }
  if (val === 'YESTERDAY') {
    date = moment(new Date())
      .subtract(1, 'day')
      .format('YYYY-MM-DD');
  }
  if (val === 'THIS_WEEK') {
    date = moment(new Date()).format('YYYY-MM-DD');
  }
  if (val === 'LAST_WEEK') {
    const d = new Date();
    const tempDate =
      d.getDay() - store.state['user'].startOfWeek < 0
        ? addWeeks(d, -1)
        : new Date();

    date = moment(
      addDays(startOfWeek(tempDate), store.state['user'].startOfWeek - 1)
    )
      .add(6, 'days')
      .add(-1, 'week')
      .format('YYYY-MM-DD');
  }
  if (val === 'THIS_MONTH') {
    date = moment(new Date()).format('YYYY-MM-DD');
  }
  if (val === 'LAST_MONTH') {
    date = moment(new Date())
      .subtract(1, 'month')
      .endOf('month')
      .format('YYYY-MM-DD');
  }
  if (val === 'THIS_YEAR') {
    date = moment(new Date()).format('YYYY-MM-DD');
  }
  if (val === 'LAST_YEAR') {
    date = moment(new Date())
      .subtract(1, 'year')
      .endOf('year')
      .format('YYYY-MM-DD');
  }

  return date;
};

export const getStartOfWeek = val => {
  if (val === 'SUNDAY') {
    return 1;
  }
  if (val === 'MONDAY') {
    return 2;
  }
  if (val === 'TUESDAY') {
    return 3;
  }
  if (val === 'WEDNESDAY') {
    return 4;
  }
  if (val === 'THURSDAY') {
    return 5;
  }
  if (val === 'FRIDAY') {
    return 6;
  }
  if (val === 'SATURDAY') {
    return 7;
  }
};
