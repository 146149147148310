
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['title', 'arrow', 'value','compTargetFormated', 'changeRate', 'line', 'sparkles', 'close'],
  data() {
    return {};
  },
  methods:{
    openChat() {
      setTimeout(() => {
        document.body['id'] = 'data-side-chat';
        this.close()
      }, 500);
    },
  },

};
