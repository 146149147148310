
import Vue from "vue";
import CircularProgress from "@/components/V2/Base/Progress/CircularProgress.vue";
import LoadingSpinner from "@/components/Common/Loading/LoadingSpinner.vue";
import AddSalesTarget from "./AddSalesTarget.vue"
import KpiTree from "./KpiTree.vue"
import SuggestedActions from "./SuggestedActions.vue"
import { mapState, mapActions, mapGetters } from "vuex";
import { differenceInCalendarDays, lastDayOfMonth } from "date-fns";
import {APIGetTriggeredMessages} from '@/api/fidaApi';

export default Vue.extend({
  components: { LoadingSpinner, CircularProgress, AddSalesTarget, KpiTree, SuggestedActions },
  
  props: {
    targets: { type: Object, default: () => {} },
    loading: { type: Boolean, default: false },
  },
  data() {
    return { 
      value: 75,
      getMonthlyTargetRate: 0,
      getDailyTargetRate: 0,
      loadingTarget: false,
      saleTargetOpen: false,
      kpiTreeOpen: false,
      sugestedOpen: false,
      salesTargetEncouragementType: 0,
      kpiTreeType: '',
      suggested: false
    };
  },
  computed: {
    ...mapGetters('user', ['getLocale']),
    importPermission(){
      var permission = this.$store.state.user.userPermissions?.FlowImport
      return permission ? true : false
    }
  },
  async created() {
      var response = await APIGetTriggeredMessages({});
      var resp = response.data.response;
      if(resp.messages.length ==0)
      {
        this.suggested = false;
      }
      else
      {
        this.suggested = true;          
      }
  },
  async mounted() {
    
  },
  methods: {
    addModel(){
      this.$refs.addSales.saleTargetOpen = true;
    },
    openKpiTree(type){
      console.log("KPI Tree clicked:", type);
      this.kpiTreeType = type;
      this.$refs.kpiTree.kpiTreeOpen = true;
      let { profile } =  this.$store.state['user'];
      const username = profile.fullName
      const email = profile.email
      const domain = profile.tenant.domain
      const _id = profile.tenant.config._id
     
      Vue.prototype?.$mixpanel?.track("KPI Tree", {
        type,
        email,
        username,
        _id,
        domain,
        referrer: document.referrer,
      });
    },
    sugestedModal(){
      this.$refs.sugestedModal.sugestedOpen = true;
      let { profile } =  this.$store.state['user'];
      const username = profile.fullName
      const email = profile.email
      const domain = profile.tenant.domain
      const _id = profile.tenant.config._id
      Vue.prototype?.$mixpanel?.track("Suggested Actions", {
        email,
        username,
        _id,
        domain,
        referrer: document.referrer,
      });
     
    },
    getTargetPercent(achieved, total) {
      if(isNaN(achieved) || isNaN(total) || total === 0) {
        return 0
      }
      return Math.round((achieved / total) * 100);
    },
  },
});
