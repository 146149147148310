
import Vue from 'vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import CommonMixin from '../../mixins/CommonMixin';
import { UPDATE_COMPANIES, VIEW_COMPANIES } from '@/consts/permissions';
import { getAuthoritiesObj } from '@/util/authoritiesCheck';
import { setUsersParamsValues } from '@/util/userParams';
import { Notify } from 'quasar';
import config from '@/config';
import LangSwitch from '../Common/LangSwitch.vue';
import store from '@/store'

const stateMap = {
  [VIEW_COMPANIES]: 'settings:company',
  [UPDATE_COMPANIES]: 'settings:company'
};

export default {
  components: { LangSwitch },
  mixins: [CommonMixin],
  data() {
    return {
      username: null,
      password: null,
      userlang: null,
      loading: false,
      disable: false,
      isHide: true,
      agreed: false,
      showSSO: false
    };
  }, // data
  computed: {
    ...mapState('user', ['profile']),
    ...mapGetters('user', ['isAuthenticated', 'getLocale']),
    ...mapGetters('tenant', ['hasResetAccount']),
    getContinueWithGmail() {
      return JSON.parse(localStorage.getItem('vuex'))?.["user"].translate.continue_with_google
    },
    ssoLoginLink() {
      return `${localStorage.getItem('sso')}/api/authGoogle?hostapp=${location.protocol + "//" + location.host}`;
    },
    getExp() {
      return store?.state?.user?.translateWhole?.google_sso_login_explaination[this?.getLocale]
    },
    getGoogle() {
      return store?.state?.user?.translateWhole?.continue_with_google?.[this?.getLocale]
    }
  },
  methods: {
    ...mapMutations('user', ['setUserPermissions', 'setDefaultSettings']),
    ...mapMutations('filter', [
      'setStoreCodes',
      'setStartingPeriod',
      'setEndingPeriod',
      'setStartingCompared',
      'setEndingCompared',
      'setCustomCalenderFlag',
      'setRetailCalendar'
    ]),
    ...mapActions('tenant', ['reset']),
    ...mapActions({
      initialize: 'tenant/initialize'
    }),
    ...mapActions('user', [
      'authenticateSSO',
      'loginUser',
      'fetchProfile',
      'fetchClientProfile',
      'authenticateDemo',
      'fetchKpiPreferences',
     // 'fetchKpiPreferences2',
      'fetchStores',
      'clearSession'
    ]),
    blockClick(a,b) {
      console.log(a, b);
      a.preventDefault();
      a.stopPropogation();
    },
    async login() {
      this.loading = true;
      this.disable = true;
      this.$v.$touch();
      this.username = this.username === null ? '' : this.username;
      this.password = this.password === null ? '' : this.password;
      try {

        if (!this.$v.$invalid) {
          const tenant = await this.loginUser({
            username: this.username,
            password: this.password,
            userlang: this.getLocale
          });

         // this.$repo.settings.getSchema(this.username, this.password);
          // const user = await this.$repo.user.getUser('me');
          // await this.fetchStores();
          // const userDetails = await this.$repo.settings.getDefaultSettings();

          // const usrRoleSetting = user.response.userDetails.role.config;
          // const usrTenentSetting = user.response.userDetails.tenant.config;

          // if (usrRoleSetting != null && usrRoleSetting != 'undefined') {
          //   userDetails.locationFilter = usrRoleSetting.allowLocationFilter;
          //   userDetails.headquartersComparePeriods =
          //     usrRoleSetting.comparePeriod;
          //   userDetails.headquartersSelectPeriods =
          //     usrRoleSetting.selectedPeriod;
          // } else {
          //   userDetails.locationFilter = usrTenentSetting.allowLocationFilter;
          //   userDetails.headquartersComparePeriods =
          //     usrTenentSetting.comparePeriod;
          //   userDetails.headquartersSelectPeriods =
          //     usrTenentSetting.selectedPeriod;
          // }
          // this.setDefaultSettings({ ...userDetails, user });
          // ////////////////

          // this.$client.setSession({
          //   tenant: tenant.intercom.companyName
          // });
          // await this.$repo.session.put(tenant.intercom.companyName);
          // //,tenant.intercom.companyId,tenant.intercom.addonsIds,tenant.intercom.plansIds
          // //
          // localStorage.setItem('userID', this.profile?._id);
          // const accountType = user.response.userDetails.plans[0].code
          //   .toString()
          //   .includes('Demo');
          // localStorage.setItem('accountType', accountType);
          // localStorage.setItem('isDemoAccount', this.profile?._id);
          // //for chat
          // localStorage.setItem('usernameChat', this.username);
          // await this.setUsersParams();
          // await this.fetchKpiPreferences();
          // //await this.fetchKpiPreferences2();

          // const userData = user.response.userDetails;
          // Vue.prototype?.$mixpanel?.identify(userData._id);
          // Vue.prototype?.$mixpanel?.people.set({
          //   $distinct_id: userData._id,
          //   $created: userData.cDate.timeStamp,
          //   $username: userData.username,
          //   $role: userData.role.title,
          //   $email_verification: userData.emailVerification.verified,
          //   $tenant_id: userData.tenant._id,
          //   $tenant_name: userData.tenant.domain,
          //   $SSO_ID: userData._id,
          //   $plan_code: userData.plans[0].code,
          //   $plan_name: userData.plans[0].code
          // });
          // Vue.prototype?.$mixpanel?.track('sign in');
          // Vue.prototype?.$mixpanel?.time_event('sign out');
          await this.$router.push({ name: 'home' }).catch(_ => {});
        }
      } catch (err) {
        this.$q.notify({
          message: this.$t('AuthPage.login_error_message'),
          type: 'negative',
          position: 'top',
          timeout: 3000
        });
        await store.dispatch('user/logout');
      } finally {
        this.loading = false;
        this.disable = false;
      }
    },

    async flowLogin() {
      try {
        const session = await this.$repo.session.fetch();
        const user = await this.$repo.user.getUser('me');
        const userDetails = await this.$repo.settings.getDefaultSettings();
        await this.setDefaultSettings({ ...userDetails, user });
        let permissions = await getAuthoritiesObj(user?.roles);
        await this.setUserPermissions(permissions);
        if (!this.hasResetAccount) {
          await this.fetchProfile();
        }
        await this.fetchProfile();
        await this.fetchClientProfile();
        await this.setUsersParams();
      // eslint-disable-next-line no-empty
      } catch (e) {
      }
    },
    async setUsersParams() {
      try {
        await setUsersParamsValues();
      } catch (e) {
        Notify.create({
          message: e,
          position: 'top',
          type: 'negative'
        });
      }
    },
    togglePassword() {
      this.isHide = !this.isHide;
    }
  },
  validations: {
    username: {
      required
    },
    password: {
      required
    }
  }
};
