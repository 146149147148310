import { render, staticRenderFns } from "./BoxKPIComparison.vue?vue&type=template&id=493133b0&scoped=true&"
import script from "./BoxKPIComparison.vue?vue&type=script&lang=js&"
export * from "./BoxKPIComparison.vue?vue&type=script&lang=js&"
import style0 from "./BoxKPIComparison.vue?vue&type=style&index=0&id=493133b0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "493133b0",
  null
  
)

/* custom blocks */
import block0 from "./BoxKPIComparison.vue?vue&type=custom&index=0&blockType=i18n&locale=en-us&lang=yaml"
if (typeof block0 === 'function') block0(component)
import block1 from "./BoxKPIComparison.vue?vue&type=custom&index=1&blockType=i18n&locale=ja&lang=yaml"
if (typeof block1 === 'function') block1(component)

export default component.exports
import {QCard,QSelect,QItem,QItemSection,QCheckbox,QItemLabel,QBtnToggle,QCardSection,QTabPanels,QTabPanel} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QSelect,QItem,QItemSection,QCheckbox,QItemLabel,QBtnToggle,QCardSection,QTabPanels,QTabPanel})
