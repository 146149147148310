import {
  APIFetchRegions,
  APIFetchDistricts,
  APIFetchStores,
  APIFetchGroups,
  APIFetchSVs,
  APIFetchKPIAnalysis,
  APIFetchKPIAnalysis2,
  APIFetchKPIAnalysisPulse,
  APIFetchKPI,
  APIFetchAllComparisions
} from '@/api/compare';
import { APIFetchCompareDates, APIFetchMarketingCampaign } from '@/api/compare';
//import { APIFetchCompareDates } from '@/api/compare';

const initialState = {
  regions: [],
  districts: [],
  stores: [],
  groups: [],
  SVs: [],
  selectedkpiTabs: {},
  selectedStores: {},
  compareDates: [],
  marketingCampaign: [],
  kpiAnalysisType: 'daily',
  kpiAnalysis: {},
  kpiAnalysisByDow: {},
  kpiMetrics: [],
  byDowCounter: 0
}; // initialState

export default {
  state: initialState, // state
  mutations: {
    setSelectedkpiTabs(state, payload) {
      state.selectedkpiTabs = payload;
    },
    setRegions(state, { regions }) {
      state.regions = regions;
    }, // setRegions
    setDistricts(state, { districts }) {
      state.districts = districts;
    }, // setDistricts
    setStores(state, { stores }) {
      state.stores = stores;
    },
    setSelectedStores(state, { stores }) {
      localStorage.removeItem('selectedStoreComparison');
      localStorage.setItem('selectedStoreComparison', JSON.stringify(stores));
      state.selectedStores = stores;
    }, // setStores
    setGroups(state, { groups }) {
      state.groups = groups;
    }, // setGroups
    setSVs(state, { SVs }) {
      state.SVs = SVs;
    }, // setSVs
    setCompareDates(state, { compareDates }) {
      state.compareDates = compareDates;
    },
    setMarketingCampaign(state, { marketingCampaign }) {
      state.marketingCampaign = marketingCampaign;
    },
    setAnalysis(state, { analysis }) {
      state.kpiAnalysis = analysis;
    },
    setAnalysisByDow(state, { analysis, daysOfWeek }) {
      state.kpiAnalysisByDow[daysOfWeek] = analysis;
      state.byDowCounter++;
    },
    setAnalysisType(state, analysisType) {
      state.kpiAnalysisType = analysisType;
    },
    setKPIMetrics(state, payload) {
      state.kpiMetrics = payload;
    }
  }, // mutations
  actions: {
    async fetchAllComparision(
      { commit },

      {
        startDate,
        endDate,
        storeCodes,
        daysOfWeek,
        useCustomCalender,
        comparisonYKPIMetricKey,
        compareStartDate,
        compareEndDate
      }
    ) {
      const res = await APIFetchAllComparisions({
        startDate,
        endDate,
        storeCodes,
        daysOfWeek,
        useCustomCalender,
        comparisonYKPIMetricKey,
        compareStartDate,
        compareEndDate
      });
      commit('setRegions', { regions: res.data.regions });
      commit('setDistricts', { districts: res.data.districts });
      commit('setStores', { stores: res.data.stores });
      commit('setGroups', { groups: res.data.peerGroups });
      commit('setSVs', { SVs: res.data.areaManagers });

      return res;
    },
    async fetchRegions(
      { commit },
      {
        startDate,
        endDate,
        storeCodes,
        daysOfWeek,
        useCustomCalender,
        comparisonYKPIMetricKey,
        compareStartDate,
        compareEndDate
      }
    ) {
      const res = await APIFetchRegions({
        startDate,
        endDate,
        storeCodes,
        daysOfWeek,
        useCustomCalender,
        comparisonYKPIMetricKey,
        compareStartDate,
        compareEndDate
      });
      commit('setRegions', { regions: res.data });

      return res;
    }, // fetchRegions
    async fetchDistricts(
      { commit },
      {
        startDate,
        endDate,
        storeCodes,
        daysOfWeek,
        useCustomCalender,
        comparisonYKPIMetricKey,
        compareStartDate,
        compareEndDate
      }
    ) {
      const res = await APIFetchDistricts({
        startDate,
        endDate,
        storeCodes,
        daysOfWeek,
        comparisonYKPIMetricKey,
        useCustomCalender,
        compareStartDate,
        compareEndDate
      });

      commit('setDistricts', { districts: res.data });

      return res;
    }, // fetchDistricts
    async fetchStores(
      { commit },
      {
        startDate,
        endDate,
        storeCodes,
        daysOfWeek,
        useCustomCalender,
        comparisonYKPIMetricKey,
        compareStartDate,
        compareEndDate
      }
    ) {
      const res = await APIFetchStores({
        startDate,
        endDate,
        storeCodes,
        daysOfWeek,
        useCustomCalender,
        comparisonYKPIMetricKey,
        compareStartDate,
        compareEndDate
      });

      commit('setStores', { stores: res.data });

      return res;
    }, // fetchStores
    async fetchGroups(
      { commit },
      {
        startDate,
        endDate,
        storeCodes,
        daysOfWeek,
        useCustomCalender,
        comparisonYKPIMetricKey,
        compareStartDate,
        compareEndDate
      }
    ) {
      const res = await APIFetchGroups({
        startDate,
        endDate,
        storeCodes,
        daysOfWeek,
        useCustomCalender,
        comparisonYKPIMetricKey,
        compareStartDate,
        compareEndDate
      });

      commit('setGroups', { groups: res.data });

      return res;
    }, // fetchGroups
    async fetchSVs(
      { commit },
      {
        startDate,
        endDate,
        storeCodes,
        daysOfWeek,
        useCustomCalender,
        comparisonYKPIMetricKey,
        compareStartDate,
        compareEndDate
      }
    ) {
      const res = await APIFetchSVs({
        startDate,
        endDate,
        storeCodes,
        daysOfWeek,
        useCustomCalender,
        comparisonYKPIMetricKey,
        compareStartDate,
        compareEndDate
      });

      commit('setSVs', { SVs: res.data });

      return res;
    }, // fetchSVs
    async fetchCompareDates({ commit }) {
      const res = await APIFetchCompareDates({});
      commit('setCompareDates', { compareDates: res.data });
      return res.data;
    },
    // async fetchMarketingCampaign({ commit }) {
    //   const res = await APIFetchMarketingCampaign({});
    //   commit('setMarketingCampaign', { marketingCampaign: res.data });
    // },
    async fetchKPIAnalysis(
      { commit },
      {
        startDate,
        endDate,
        storeCodes,
        selectedKPI,
        compareKPI,
        type,
        compareStartDate,
        compareEndDate,
        useCustomCalender,
        daysOfWeek
      }
    ) {
      const res = await APIFetchKPIAnalysis(
        {
          startDate,
          endDate,
          storeCodes,
          compareStartDate,
          compareEndDate,
          selectedKPI,
          useCustomCalender,
          compareKPI,
          daysOfWeek
        },
        type
      );
      console.log(res.data, 'res.data');
      commit('setAnalysis', { analysis: res.data });
      return res;
    },
    async fetchKPIAnalysisHourly(
      { commit },
      {
        startDate,
        endDate,
        storeCodes,
        selectedKPI,
        compareKPI,
        type,
        compareStartDate,
        compareEndDate,
        useCustomCalender,
        daysOfWeek,
        setbyDow
      }
    ) {
      //GetReportingSumDataFidaV2
      const res: any = await APIFetchKPIAnalysisPulse(
        {
          startDate,
          endDate,
          storeCodes,
          compareStartDate,
          compareEndDate,
          selectedKPI,
          useCustomCalender,
          compareKPI,
          daysOfWeek
        },
        'hourly'
      );

      commit('setAnalysis', { analysis: res });
      if (setbyDow) {
        commit('setAnalysisByDow', {
          analysis: res,
          daysOfWeek: daysOfWeek + setbyDow
        });
      }

      return res;
    },
    async fetchKPIMetrics({ commit }) {
      let res = await APIFetchKPI({});
      commit('setKPIMetrics', res.data);
    },
    async fetchKPIAnalysis2(
      { commit },
      {
        startDate,
        endDate,
        storeCodes,
        selectedKPI,
        compareKPI,
        type,
        compareStartDate,
        compareEndDate,
        useCustomCalender,
        daysOfWeek
      }
    ) {
      const res = await APIFetchKPIAnalysis2(
        {
          startDate,
          endDate,
          storeCodes,
          compareStartDate,
          compareEndDate,
          selectedKPI,
          useCustomCalender,
          compareKPI,
          daysOfWeek
        },
        type == 'monthly' ? 'daily' : type
      );
      if (type === 'monthly') {
        const aggregate = trend => {
          trend.selectedPeriod = aggregateDataByMonth(trend.selectedPeriod);
          trend.comparedPeriod = aggregateDataByMonth(trend.comparedPeriod);
        };
        aggregate(res.data.selectedKPITrend);
        aggregate(res.data.comparedKPITrend);
      }
      
      if(type === 'weekly') {
        res.data.comparedKPITrend =  trimComparedPeriod(res.data.comparedKPITrend);
        res.data.selectedKPITrend =  trimComparedPeriod(res.data.selectedKPITrend);
      }
      commit('setAnalysis', { analysis: res.data });
      return res;
    }
  } // actions
}; 
function trimComparedPeriod(comparedKPITrend) {
  const { comparedPeriod, selectedPeriod } = comparedKPITrend;

  if (comparedPeriod.length > selectedPeriod.length) {
      const extraItemsCount = comparedPeriod.length - selectedPeriod.length;
      comparedPeriod.splice(-extraItemsCount, extraItemsCount);
  }

  // Sort the array by timelineLabel in ascending order
  comparedPeriod.sort((a, b) => {
    const dateA:any = new Date(a.timelineLabel.match(/\((\d{4}-\d{2}-\d{2})/)[1]);
    const dateB:any = new Date(b.timelineLabel.match(/\((\d{4}-\d{2}-\d{2})/)[1]);
    return dateA - dateB;
  });

   // Sort the array by timelineLabel in ascending order
   selectedPeriod.sort((a, b) => {
    const dateA:any = new Date(a.timelineLabel.match(/\((\d{4}-\d{2}-\d{2})/)[1]);
    const dateB:any = new Date(b.timelineLabel.match(/\((\d{4}-\d{2}-\d{2})/)[1]);
    return dateA - dateB;
  });

  return comparedKPITrend;
}
function aggregateDataByMonth(data) {
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  
  const result = data.reduce((acc, item) => {
      const date = new Date(item.timelineLabel);
      const yearMonth = `${date.getFullYear()}-${date.getMonth() + 1}`;
      const monthName = monthNames[date.getMonth()];
      
      if (!acc[yearMonth]) {
          acc[yearMonth] = {
              ...item,
              metricValue: 0,
              timelineLabel: `${monthName}`,
              year:`${date.getFullYear()}`
          };
      }
      
      acc[yearMonth].metricValue += item.metricValue;
      acc[yearMonth].formattedMetricValue = `¥${acc[yearMonth].metricValue.toLocaleString()} `;
            acc[yearMonth].sales = acc[yearMonth].formattedMetricValue;
      
      return acc;
  }, {});
  
  return Object.values(result);
}