// let hasUpdated = false;
// setInterval(() => {
//   let session = JSON.parse(localStorage.getItem('vuex') || "{}")?.["user"]?.session || {};
//     if(Object.keys(session).length && !hasUpdated) {
//       hasUpdated = true
//       setTimeout(() => {
//         this.setCalendarDatesToStore(
//           {
//             selectedOption: JSON.parse(localStorage.getItem('vuex') || "{}")?.["user"]?.defaultSettings?.storeSelectPeriods,
//             comparedOption: JSON.parse(localStorage.getItem('vuex') || "{}")?.["user"]?.defaultSettings?.storeComparePeriods
//           })
//         // filterExport.state["selectedOption"] = JSON.parse(localStorage.getItem('vuex') || "{}")?.["user"]?.defaultSettings?.storeSelectPeriods,
//         // filterExport.state["comparedOption"] = JSON.parse(localStorage.getItem('vuex') || "{}")?.["user"]?.defaultSettings?.storeComparePeriods
//         console.log(JSON.parse(localStorage.getItem('vuex') || "{}")?.["user"]?.defaultSettings?.storeSelectPeriods, JSON.parse(localStorage.getItem('vuex') || "{}")?.["user"]?.defaultSettings?.storeComparePeriods)
    
//       }, 1000)
//     }
//     if(Object.keys(session).length < 1) {
//       hasUpdated = false;
//     }
// }, 1000)

export function getDayDiffFromMonday() {
  const weekday = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
  let day = JSON.parse(localStorage.getItem('vuex') || "{}")?.["user"]?.startWeek?.toLowerCase() || "sunday"
  let i =  weekday.indexOf(day) > -1 ? weekday.indexOf(day) : 0
  return i;
}
export function getStartDayOfWeek() {
  const weekday = ["sunday","monday", "tuesday", "wednesday", "thursday","friday","saturday"];
  let day = JSON.parse(localStorage.getItem('vuex') || "{}")?.["user"]?.startWeek?.toLowerCase() || "sunday"
  return weekday.indexOf(day) > -1 ? weekday.indexOf(day) : 0
}
export default {
  
  getDayDiffFromMonday,
  getStartDayOfWeek,
}

