
export default {
  props: {
    value: {
      type: Number,
      required: true,
      default: 0,
    }, // elevated
    value2: {
      type: Number,
      required: false,
      default: 0,
    }, // elevated
    achieved: {
      type: [String,Number],
      required: true,
      default: '0',
    }, // elevated
    expected: {
      type: [String],
      required: true,
      default: '0',
    },
    isTarget: {
      type: Boolean,
      default: false
    },
    tarType: {
      type: Number,
      default: 0
    }
  }, // props
  data() {
    return {
      limitPerc: false
    }
  },
  computed:{
    percentage(){
      if (isNaN(this.value) && !Number.isFinite(this.value)) return 0
      return this.value <= 100 ? this.value : 100
    },
    getValueExp(){
      var num = (this.value2 / 10)
      return (num / 10);
    },
    getValue(){
      var num = (this.value / 10)
      return (num / 10);
    },
    getPerc() {
      if (isNaN(this.value) || isNaN(this.value2) || !this.value2)  return 0
      return ((this.value/this.value2)*100).toFixed(0)
    },
    getPercDiff() {
      if (isNaN(this.value) || isNaN(this.value2))  return 0
      return Math.abs(this.value - this.value2).toFixed(0)
    },
    // isAhead() {
    //   return this.getValue() >= this.getValueExp()
    // },
    // percAhead() {
    //   return ((this.getValueExp() * 100) / this.getValue()) - 100;
    // }
  },
  async mounted() {

    let companyID = JSON.parse(localStorage.getItem('vuex'))?.user?.profile?.tenant?.intercom?.companyId;
    this.limitPerc = this.isTarget && companyID === '3rh6jKy7Ln8vma3sM'
  }
};
