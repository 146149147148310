const initialState = {
  comparisonTab: 'store'
};

export default {
  state: initialState,
  mutations: {
    setComparisonTab(state, { tab }) {
      state.comparisonTab = tab;
      localStorage.removeItem('tabComparison');
      localStorage.setItem('tabComparison', tab);
    }
  }
};
