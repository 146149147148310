import axios from 'axios';
import store from '@/store'
//check
export function getLocaleMehod() {

    axios.get('https://flow-public-resource.s3.ap-northeast-1.amazonaws.com/localization/client_app.json',{headers:{
        Authorization:''
    }})
  .then(function(response) {
    let data = response;
    let langObj = {};
    const storeState: any = store.state;
    var lang = storeState.user.locale || 'en';
    for (const key in data) {
        langObj[key] = data[key][lang];
    }
    store.commit('user/setTranslations', langObj)
    store.commit('user/setTranslationsWhole', data)
  })
  .catch(function(error) {
    //console.log(error);
  })
 }
 export function getLocaleMethodKpi() {

  axios.get('https://flow-public-resource.s3.ap-northeast-1.amazonaws.com/localization/client_kpis.json',{headers:{
      Authorization:''
  }})
.then(function(response) {
  let data = response;
  let langObj = {};
  const storeState: any = store.state;
  var lang = storeState.user.locale || 'en';
  for (const key in data) {
      langObj[key] = data[key];
  }

  store.commit('user/setTranslationsKpi', langObj)
})
.catch(function(error) {
  //console.log(error);
})
}
