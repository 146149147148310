
import {formatValues, compatibleList, compListToFida, getObjByKey, sortingKpiOrder, avgList, notFixed, zeroFixed,variationLocale, variationInNumbers, variationInNumbersPadded, directCalcObj, variationPercDiff} from '@/api/apiDataManagers/calc_consts';
import {getObjByKeyTree} from '@/api/apiDataManagers/calc_consts_tree';

import { getSignsLeftSide,getSignsRightSide } from '@/util/replaceFidaKpi';

export function sumAll(obj, d, area) {
  if(!obj.length) return {};
  let count = {};
  let retVal = {};
  retVal.tra_sls = {}
  retVal.tra_st = {}

  obj.forEach((entry) => {
    let st = entry._id.storeCode
    Object.keys(entry).forEach(k => {
      let k2 = k.toLowerCase()
      if(k2 != "_id") {
        if(isNaN(retVal[k2])) retVal[k2] = 0;
        if(isNaN(count[k2])) count[k2] = 0;
        if(k2 == "dw" || k2 == "tra") {
          if(k2 == "dw") {
            if(entry[k]) {
              retVal[k2] += Number(entry[k])
              count[k2] += 1
            }
          } else if(k2 == "tra") {
            retVal[k2] += directCalcObj.directCalcFn(k2, entry, area)
            retVal.tra_sls[st] = (retVal.tra_sls[st] || 0) + (entry.sls || 0);
            retVal.tra_st[st] = (retVal.tra_st[st] || 0) + (entry.st || 0);
            count[k2] += 1
          }
        } else if(directCalcObj.isDirectCalc(k2)) {
          retVal[k2] += directCalcObj.directCalcFn(k2, entry, area)
          count[k2] += 1
        } else {
          retVal[k2] += Number(entry[k])
          count[k2] += 1
        }
      }
    })
  })

  avgList.forEach((a) => {
    if(retVal[a] && count[a]) {
      retVal[a] /= count[a]
    }
  })


  let sortedRetVal = {}
  sortingKpiOrder.forEach((k) => {
    if(retVal[k]) {
      sortedRetVal[k] = retVal[k]
      delete retVal[k]
    }
  })
  Object.keys(retVal).forEach((k) => {
    sortedRetVal[k] = retVal[k]
  })

  return sortedRetVal;
}


function decimalDigit(val) {
  return Math.round(val*100)/100;
}

function getArea(kpi, stores) {
  let areaObj = {}

  Object.keys(stores).forEach((k) => {
    stores[k].forEach((j, ind) => {
      kpi.forEach((l) => {
        if(l._id.storeCode == j.storeNumber) {
          areaObj[j.storeNumber] = Number(j.area) || 0;
        }
      })
    })
  })
  return Object.values(areaObj).reduce((a, b) => a+b, 0);
}
function getCount(value, d) {
  let stores = {};
  let dates = {};
  value.forEach((itm) => {
    dates[itm._id[d]] = true;
    stores[itm._id.storeCode] = true;
  })
  return [Object.keys(stores).length, Object.keys(dates).length]
}

function getConvertedValuesSimplified(kpis, compare) {
  let retVal = {}
  Object.keys(kpis).forEach((key) => {
    key = compListToFida[key] || key;
    let isValid = getObjByKey(key);
    if(isValid) {

      let sign = '', perc = '', x=kpis[key], y=compare[key], a=0, b=0;
      if(x.indexOf('¥') > -1) {
        sign = '¥';
        x=x.substr(1, x.length-1);
        y=y.substr(1, y.length-1);
      }

      if(x.indexOf('%') > -1) {
        perc = '%';
        x=x.substr(0, x.length-1);
        y=y.substr(0, y.length-1);
      }

      if(key == "dw") {
        a = Number(x.split("min")[0])*60 + Number(x.split(":")[1].split('s')[0])
        b = Number(y.split("min")[0])*60 + Number(y.split(":")[1].split('s')[0])
      } else {
        a = Number(x.split(',').join(''))
        b = Number(y.split(',').join(''))
      }


      retVal[key] = isValid

      retVal[key].value = kpis[key];
      retVal[key].comparedValue = compare[key];
      retVal[key].variation = a - b;
      retVal[key].variationPercentage = b==0 || a==0 ? 0 : decimalDigit(((a)/b) * 100).toFixed(2);
      retVal[key].growthIndicator = a < b ? - 1 : 1;
      let df1 = 0
      let igKeys = []
      if((b==0 && igKeys.indexOf(key) < 0)) {
        df1 = 0
      } else {
        df1 = key == 'sl' ? Math.round(a) - Math.round(b) : a - b;
      }
      if(variationInNumbers.indexOf(key) > -1 ) {
        retVal[key].variance = (df1).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0});
      } else if(variationInNumbersPadded.indexOf(key) > -1 ) {
        retVal[key].variance = df1.toLocaleString(undefined, { minimumFractionDigits: df1 == 0 ? 0:2, maximumFractionDigits:  df1 == 0 ? 0:2});
      } else if(variationPercDiff.indexOf(key) > -1) {
        retVal[key].variance = df1 .toLocaleString(undefined, { minimumFractionDigits: df1 == 0 ? 0:2, maximumFractionDigits:  df1 == 0 ? 0:2});
        retVal[key].variance += '%';
      } else if(variationLocale.indexOf(key) > -1) {
        retVal[key].variance = (df1).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits:  0});
      } else if(key === "dw") {
        retVal[key].variance = (df1).toFixed(0) + 'mm';
      } else {
        retVal[key].variance = retVal[key].variationPercentage == 0 ? "0" : (Math.abs(retVal[key].variationPercentage)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits:  2});
        retVal[key].variance += '%';
      }
      retVal[key].variationPercentage += '%'
    }
  })
  return retVal
}

function getConvertedValues(value, cValue, oValue, oCValue, extendedData) {
  let retVal = {}
  const allKeys = extendedData.userKPIs;

  allKeys.forEach((key)=> {
    key = compListToFida[key] || key;
    let a = Number(value[key]), b = Number(cValue[key]) || 0;
    
    if(directCalcObj.isDirectCalc(key)) {
      a = directCalcObj.directCalcFn(key, value, extendedData.area)
      b = directCalcObj.directCalcFn(key, cValue, extendedData.cArea)
    }
    if(key == "spt" || key == "tpt") {
      a /= extendedData.d1
      b /= extendedData.d2
    }
    a = a || 0
    b = b || 0
    if(!isNaN(a) && !isNaN(b)) {
      let isValid = getObjByKey(key);
      if(isValid) {
        key = isValid?.metricID || key
        let isZeroFixed = zeroFixed.indexOf(key) > -1;
        let slSe = ((key == 'sl'  || key == 'cs' || key == 'se') && !value.sh) ||
        ((key == 'tra' ) && !value.st);
        retVal[key] = isValid
        retVal[key].value = slSe ? a.toFixed(2) : notFixed.indexOf(key) > -1 ? a : isZeroFixed? a.toFixed(0) : a.toFixed(2);
        retVal[key].comparedValue = slSe ? b.toFixed(2) : notFixed.indexOf(key) > -1 ? b : isZeroFixed? b.toFixed(0) : b.toFixed(2);
        retVal[key].variation = a - b;
        // retVal[key].variationPercentage = b==0 ? a : decimalDigit(((a-b)/b) * 100).toFixed(2);
        retVal[key].variationPercentage = b==0 || a==0 ? 0 : decimalDigit(((a)/b) * 100).toFixed(2);
        retVal[key].growthIndicator = slSe ? 1 : a < b ? - 1 : 1;
        // retVal[key].growthIndicator = a == b || b==0 || a==0? 0 : a < b ? - 1 : 1;
        let df1 = 0
        let igKeys = []
        if((b==0 && igKeys.indexOf(key) < 0)|| slSe) {
          df1 = 0
        } else {
          df1 = key == 'sl' ? Math.round(a) - Math.round(b) : a - b;
        }
        
        if(variationInNumbers.indexOf(key) > -1 ) {
          retVal[key].variance = slSe ? '0' :(df1).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0});
        } else if(variationInNumbersPadded.indexOf(key) > -1 ) {
          retVal[key].variance = df1.toLocaleString(undefined, { minimumFractionDigits: df1 == 0 ? 0:2, maximumFractionDigits:  df1 == 0 ? 0:2});
        } else if(variationPercDiff.indexOf(key) > -1) {
          retVal[key].variance = df1 .toLocaleString(undefined, { minimumFractionDigits: df1 == 0 ? 0:2, maximumFractionDigits:  df1 == 0 ? 0:2});
          retVal[key].variance += '%';
        } else if(variationLocale.indexOf(key) > -1) {
          retVal[key].variance = (df1).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits:  0});
        } else if(key === "dw") {
          retVal[key].variance = (df1).toFixed(0) + 'mm';
        } else {
          retVal[key].variance = retVal[key].variationPercentage == 0 ? "0" : (Math.abs(retVal[key].variationPercentage)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits:  2});
          retVal[key].variance += '%';
        }

        retVal[key].variationPercentage += '%'
        if(key == "tra") {
          retVal[key].value =  formatValues(Number(retVal[key].value).toFixed(2), key);
          retVal[key].comparedValue =  formatValues(Number(retVal[key].comparedValue).toFixed(2), key);
        } else if(getSignsRightSide(key) === ' %') {
          retVal[key].value =  formatValues(retVal[key].value, key);
          retVal[key].comparedValue =  formatValues(retVal[key].comparedValue, key);
        } else {
          if(key == "dw" || key == "spt") {
            retVal[key].value =  formatValues(Math.floor(retVal[key].value), key);
            retVal[key].comparedValue =  formatValues(Math.floor(retVal[key].comparedValue), key);
          } else if(key === 'upt' || key == "tpt") {
            retVal[key].value =  formatValues(Number(retVal[key].value).toLocaleString(undefined, {minimumFractionDigits: 2}), key);
            retVal[key].comparedValue =  formatValues(Number(retVal[key].comparedValue).toLocaleString(undefined, {minimumFractionDigits: 2}), key);
          } else {
            retVal[key].value =  formatValues(Number(retVal[key].value).toLocaleString(), key);
            retVal[key].comparedValue =  formatValues(Number(retVal[key].comparedValue).toLocaleString(), key);
          }
        }
      }
    }
  })
  let sortedRetVal = {}
  sortingKpiOrder.forEach((k) => {
    if(retVal[k]) {
      sortedRetVal[k] = retVal[k]
      delete retVal[k]
    }
  })

  Object.keys(retVal).forEach((k, i) => {
    sortedRetVal[k] = retVal[k]
  })

  return sortedRetVal;

}
function getConvertedValuesForKpiTree(value, cValue, oValue, oCValue, extendedData) {
  let retVal = {}
  let kpiList = localStorage.getItem("kpiList");
  const allKeys =  kpiList.split(',');;

  allKeys.forEach((key)=> {
    key = compListToFida[key] || key;
    let a = Number(value[key]), b = Number(cValue[key]) || 0;
    
    if(directCalcObj.isDirectCalc(key)) {
      a = directCalcObj.directCalcFn(key, value, extendedData.area)
      b = directCalcObj.directCalcFn(key, cValue, extendedData.cArea)
    }
    if(key == "spt" || key == "tpt") {
      a /= extendedData.d1
      b /= extendedData.d2
    }
    a = a || 0
    b = b || 0
    if(!isNaN(a) && !isNaN(b)) {
      let isValid = getObjByKeyTree(key);
      if(isValid) {
        key = isValid?.metricID || key
        let isZeroFixed = zeroFixed.indexOf(key) > -1;
        let slSe = ((key == 'sl'  || key == 'cs' || key == 'se') && !value.sh) ||
        ((key == 'tra' ) && !value.st);
        retVal[key] = isValid
        retVal[key].value = slSe ? a.toFixed(2) : notFixed.indexOf(key) > -1 ? a : isZeroFixed? a.toFixed(0) : a.toFixed(2);
        retVal[key].comparedValue = slSe ? b.toFixed(2) : notFixed.indexOf(key) > -1 ? b : isZeroFixed? b.toFixed(0) : b.toFixed(2);
        retVal[key].valuePlain =  slSe ? a.toFixed(2) : notFixed.indexOf(key) > -1 ? a : isZeroFixed? a.toFixed(0) : a.toFixed(2);
        retVal[key].comparedValuePlain =  slSe ? b.toFixed(2) : notFixed.indexOf(key) > -1 ? b : isZeroFixed? b.toFixed(0) : b.toFixed(2);
        retVal[key].compTarget = 0;
        retVal[key].variation = a - b;
        // retVal[key].variationPercentage = b==0 ? a : decimalDigit(((a-b)/b) * 100).toFixed(2);
        retVal[key].variationPercentage = b==0 || a==0 ? 0 : decimalDigit(((a)/b) * 100).toFixed(2);
        retVal[key].growthIndicator = slSe ? 1 : a < b ? - 1 : 1;
        // retVal[key].growthIndicator = a == b || b==0 || a==0? 0 : a < b ? - 1 : 1;
        let df1 = 0
        let igKeys = []
        if((b==0 && igKeys.indexOf(key) < 0)|| slSe) {
          df1 = 0
        } else {
          df1 = key == 'sl' ? Math.round(a) - Math.round(b) : a - b;
        }
        
        if(variationInNumbers.indexOf(key) > -1 ) {
          retVal[key].variance = slSe ? '0' :(df1).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0});
        } else if(variationInNumbersPadded.indexOf(key) > -1 ) {
          retVal[key].variance = df1.toLocaleString(undefined, { minimumFractionDigits: df1 == 0 ? 0:2, maximumFractionDigits:  df1 == 0 ? 0:2});
        } else if(variationPercDiff.indexOf(key) > -1) {
          retVal[key].variance = df1 .toLocaleString(undefined, { minimumFractionDigits: df1 == 0 ? 0:2, maximumFractionDigits:  df1 == 0 ? 0:2});
          retVal[key].variance += '%';
        } else if(variationLocale.indexOf(key) > -1) {
          retVal[key].variance = (df1).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits:  0});
        } else if(key === "dw") {
          retVal[key].variance = (df1).toFixed(0) + 'mm';
        } else {
          retVal[key].variance = retVal[key].variationPercentage == 0 ? "0" : (Math.abs(retVal[key].variationPercentage)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits:  2});
          retVal[key].variance += '%';
        }

        retVal[key].variationPercentage += '%'
        if(key == "tra") {
          retVal[key].value =  formatValues(Number(retVal[key].value).toFixed(2), key);
          retVal[key].comparedValue =  formatValues(Number(retVal[key].comparedValue).toFixed(2), key);
          retVal[key].valuePlain =  Number(retVal[key].valuePlain).toFixed(2);
          retVal[key].comparedValuePlain =  Number(retVal[key].comparedValuePlain).toFixed(2);
        } else if(getSignsRightSide(key) === ' %') {
          retVal[key].value =  formatValues(retVal[key].value, key);
          retVal[key].comparedValue =  formatValues(retVal[key].comparedValue, key);
          // retVal[key].valuePlain =  retVal[key].valuePlain;
          // retVal[key].comparedValuePlain =  retVal[key].comparedValuePlain;
        } else {
          if(key == "dw" || key == "spt") {
            retVal[key].value =  formatValues(Math.floor(retVal[key].value), key);
            retVal[key].comparedValue =  formatValues(Math.floor(retVal[key].comparedValue), key);
            retVal[key].valuePlain =  Math.floor(retVal[key].valuePlain);
            retVal[key].comparedValuePlain =  Math.floor(retVal[key].comparedValuePlain);
          } else if(key === 'upt' || key == "tpt") {
            retVal[key].value =  formatValues(Number(retVal[key].value).toLocaleString(undefined, {minimumFractionDigits: 2}), key);
            retVal[key].comparedValue =  formatValues(Number(retVal[key].comparedValue).toLocaleString(undefined, {minimumFractionDigits: 2}), key);
            retVal[key].valuePlain =  (Number(retVal[key].valuePlain));
            retVal[key].comparedValuePlain =  (Number(retVal[key].comparedValuePlain));
          } else {
            retVal[key].value =  formatValues(Number(retVal[key].value).toLocaleString(), key);
            retVal[key].comparedValue =  formatValues(Number(retVal[key].comparedValue).toLocaleString(), key);
            retVal[key].valuePlain =  Number(retVal[key].valuePlain);
            retVal[key].comparedValuePlain =  Number(retVal[key].comparedValuePlain);
          }
        }
      }
    }
  })
  let sortedRetVal = {}
  sortingKpiOrder.forEach((k) => {
    if(retVal[k]) {
      sortedRetVal[k] = retVal[k]
      delete retVal[k]
    }
  })

  Object.keys(retVal).forEach((k, i) => {
    sortedRetVal[k] = retVal[k]
  })

  return sortedRetVal;

}
/* 
if decival has key then that value is in point and when trend graph is drawn the graph will be precise to decimal point
if deciformaater has key than that value is shown in points on hover
*/
const deciVal = ["upt", "cn", "tpt", "cr", "tra", "sh"]
const deciFormatted = ["upt", "cn", "tpt", "cr", "tra"]
const isLocale = ["sls"]
export function resetTrendValue(kpi, key) {
  [kpi.comparedPeriod, kpi.selectedPeriod].forEach((arr) => {
    arr.forEach((itm, i) => {
      let isDeci = deciVal.indexOf(key) > -1;
      let v = isDeci ? Number(itm[key]).toFixed(2) : Math.round(itm[key]);
      arr[i]["value"] = Number(v)
      let isFormDeci = deciFormatted.indexOf(key) > -1;
      v = isFormDeci ? Number(itm[key]).toFixed(2) : Math.round(itm[key]);
      if(key == "dw") {
        arr[i]["formattedValue"] = formatValues(Number(v), key);
      } else {
        arr[i]["formattedValue"] = formatValues(Number(v).toLocaleString(undefined, {minimumFractionDigits: (isFormDeci) ? 2 : 0}), key);
      }
    })
  })
}

function getConvertedValuesLocation(value) {
  let retVal = {}
  if(!value) return retVal

  value.forEach((kpi) => {
    Object.keys(kpi).forEach((key)=> {
      let kL = key.toLowerCase();
      let slSe = ((kL == 'sl'  || kL == 'cs' || kL == 'se') && !kpi.sh) ||
      ((kL == 'tra' ) && !kpi.st);
      if(!retVal[kL]) { retVal[kL] = 0; }
      if(key != "_id" && kpi[key]) {
        retVal[kL] += slSe ? 0 : Number(kpi[key])
      }
    })
  })
  Object.keys(retVal).forEach((k) => {
    if(avgList.indexOf(k) > -1) {
      retVal[k] = retVal[k] / (value.length || 1)
    }
  })

  return retVal;
}


export function resetComparisionByLocation(obj, key) {
  obj.forEach((itm) => {
    itm.value = String(itm["sls" + "_val"]);
    itm.yAxisValue = String(itm[key + "_val"]);
    itm.formattedYAxisValue = formatValues(itm[key + "_val"], key)
  })
}

const isBubbleRounded = ["ap", "cn", "cr", "rov", "aip", "spt"]
function formatComparisionByLocation(resp, typedStores, type, metricKey, storeNamesbyCode, area, extData) {
  let retVal = []
  let tempType = type

  Object.keys(typedStores).forEach((tK) => {
    let cData = resp.kpis.filter((e) => typedStores[tK].indexOf(e._id.storeCode) > -1 );
    let sumedData = getConvertedValuesLocation(cData)
    if(Object.keys(sumedData).length) {
      // let metricValue = directCalcObj.isDirectCalc(metricKey) ? directCalcObj.directCalcFn(metricKey, sumedData, area) : sumedData[metricKey];
      let thisData = {
        "valueLabel": type == "stores" ? storeNamesbyCode[tK]: tK,
        "xAxisLabel": "Traffic",
        "yAxisLabel": tempType.charAt(0).toUpperCase() + tempType.slice(1),
        "xAxisValue": (sumedData.trf || 0).toFixed(0),
        // "value": String((sumedData.sls || 0).toFixed(0)),
        // "yAxisValue": String(metricValue),
        // "formattedYAxisValue": formatValues(metricValue, metricKey),
        "formattedXAxisValue": formatValues((sumedData.trf || 0), "trf"),
        // "sls": formatValues(Number((sumedData.sls || 0)).toLocaleString(), "sls"),
        // "trf": formatValues((sumedData.trf || 0), "trf"),
        // "cn": formatValues(conversion.toFixed(2), "cn"),
        // "ap": formatValues((Number(avgpurchase.toFixed(0))).toLocaleString(), "ap"),
        // "cr": formatValues(capturerate.toFixed(2), "cr"),
        // "is": formatValues(sumedData.is, "is"),
      };

      extData.kpis.forEach((k) => {
        let val = directCalcObj.isDirectCalc(k) ? directCalcObj.directCalcFn(k , sumedData, area) : sumedData[k];
        let isDec = isBubbleRounded.indexOf(k) < 0 && (val - Math.floor(val)) > 0;
        if((getSignsLeftSide(k).trim() || getSignsRightSide(k).trim())) {
          thisData[k] = formatValues(val.toLocaleString(undefined, {maximumFractionDigits: isDec ? 2 : 0}), k);
        } else {
          thisData[k] = formatValues(isDec ? val.toFixed(2) : Math.round(val), k);
        }
        thisData[k+"_val"] = isDec ? val.toFixed(2) : Math.round(val);
      })

      retVal.push(thisData)
    }
  })
  resetComparisionByLocation(retVal, metricKey)
  return JSON.parse(JSON.stringify(retVal));
}

let tL = {
  // "sls": "sales",
  // "pb": "passerby",
  // "trn": "transactions",
  // "trf": "traffic",
  // "in": "trafficin",
  // "out": "trafficout",
  // "sh": "staffhours",
  // "is": "totalitemsold",
  // "dw": "storedwelltime",
}

function adjustDateId(itm, dT) {
  let dO = itm._id.split('_')
  itm._id = {
    storeCode: dO[1]
  }

  dO = dO[0];
  dO = dO.split('');
  if(dT == "date") {
    dO.splice(6, 0, "-");
    dO.splice(4, 0, "-");
  }
  let newD = dO.join('');

  itm._id[dT] = newD
  Object.keys(itm).forEach((k) => {
    if(tL[k]) {
      itm[tL[k]] = itm[k];
      delete itm[k];
    }
  })
}

function formatV2Resp(obj, dT) {

  obj?.kpis?.forEach((itm) => {
    adjustDateId(itm, dT)
  })
  obj?.kpisCompare?.forEach((itm) => {
    adjustDateId(itm, dT)
  })

}



function getConvertedValuesByPeriod(value, cValue, oValue, oCValue, extendedData) {
  let retVal = {}
  retVal["selectedKPIValues"] = []
  retVal["comparedKPIValues"] = []
  const allKeys = extendedData.userKPIs;

  allKeys.forEach((key)=> {
    key = compListToFida[key] || key;
    let a = Number(value[key]), b = Number(cValue[key]) || 0;
    if(directCalcObj.isDirectCalc(key)) {
      a = directCalcObj.directCalcFn(key, value, extendedData.area)
      b = directCalcObj.directCalcFn(key, cValue, extendedData.cArea)
    }
    if(key == "spt" || key == "tpt") {
      a /= extendedData.d1
      b /= extendedData.d2
    }
    a = a || 0
    b = b || 0
    if(!isNaN(a) && !isNaN(b)) {
      let isSelected = getObjByKey(key);
      let isCompared = getObjByKey(key);
      if(isSelected) {

        key = isSelected?.metricID || key
        isSelected.value = notFixed.indexOf(key) > -1 ? a : zeroFixed.indexOf(key) > -1 ? a.toFixed(0) : a.toFixed(2);
        isCompared.value = notFixed.indexOf(key) > -1 ? b : zeroFixed.indexOf(key) > -1 ? b.toFixed(0) : b.toFixed(2);

        if(getSignsRightSide(key) === ' %' || key == "tpt" || key == "spt" || key == "dw") {
          isSelected.formattedValue = formatValues(isSelected.value, key);
          isCompared.formattedValue =  formatValues(isCompared.value, key);
        } else {
          if(key === 'upt') {
            isSelected.formattedValue = formatValues(Number(isSelected.value).toLocaleString(undefined, {minimumFractionDigits: 2}), key);
            isCompared.formattedValue =  formatValues(Number(isCompared.value).toLocaleString(undefined, {minimumFractionDigits: 2}), key);
          } else {
            isSelected.formattedValue = formatValues(Number(isSelected.value).toLocaleString(), key);
            isCompared.formattedValue =  formatValues(Number(isCompared.value).toLocaleString(), key);
          }
        }
        retVal["selectedKPIValues"].push(isSelected)
        retVal["comparedKPIValues"].push(isCompared)
      }

    }
  })

  // let sortedRetVal = {}
  // sortingKpiOrder.forEach((k) => {
  //   if(retVal[k]) {
  //     sortedRetVal[k] = retVal[k]
  //     delete retVal[k]
  //   }
  // })

  // Object.keys(retVal).forEach((k, i) => {
  //   sortedRetVal[k] = retVal[k]
  // })

  return retVal;

}

export default {
  getObjByKey,
  sumAll,
  getConvertedValues,
  getArea,
  formatComparisionByLocation,
  getCount,
  formatV2Resp,
  getConvertedValuesByPeriod,
  getConvertedValuesSimplified,
  getConvertedValuesForKpiTree

}
