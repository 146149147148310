
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
import { QUAD_TONES } from '../../util/colors';
import { formatYAxis } from '../../util/legend';
import { weatherObj } from '../../util/weatherIcons';
import weather from '../../../public/weather.js';
import moment from 'moment';

export default {
  props: {
    selectedkpi: {
      type: String,
      default: '',
      required: true
    },
    comparekpi: {
      type: String,
      default: '',
      required: true
    },
    setselectedkpi: {
      type: Function,
      required: true
    },
    setcomparekpi: {
      type: Function,
      required: true
    }
  },
  data: function() {
    return {
      granularityOptions: [
        { label: this.$t('Date.hourly'), value: 'hourly' },
        { label: this.$t('Date.daily'), value: 'daily' },
        { label: this.$t('Date.weekly'), value: 'weekly' },
        { label: this.$t('Date.monthly'), value: 'monthly' }
      ],
      axisVisibility: {
        index1: true,
        index2: true,
        index3: false,
        index4: false
      },
      hourlyWeather: [],
      hourlyWeatherC: [],
      selectedIcon: [],
      minTemp: [],
      maxTemp: [],
      minTemp2: [],
      maxTemp2: [],
      weatherData: [],
      selectedIcon2: [],
      weatherData2: [],
      analysisType: 'daily',
      selectedKPI: '',
      compareKPI: '',
      kpiCategories: [],
      kpiCategories2: [],
      // series: [],
      loading: true,
      series: [],
      chartOptions: {
        chart: {
          events: {
            legendClick: (chartContext, seriesIndex, config) => {
              if (seriesIndex === 0) {
                this.axisVisibility.index1 = !this.axisVisibility.index1;
                this.axisVisibility.index3 = !this.axisVisibility.index1;
              }
              if (seriesIndex === 1) {
                this.axisVisibility.index2 = !this.axisVisibility.index2;
                this.axisVisibility.index4 = !this.axisVisibility.index2;
              }
              this.setYaxisVals();
            }
          },
          height: 450,
          type: 'line',
          dropShadow: {
            enabled: false,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: true,
            tools: {
              download: '<i class="fas fa-download"></i>'
            }
          }
        },
        fill: {
          type: 'solid',
          opacity: [1, 0.3, 1, 1]
        },
        colors: QUAD_TONES,
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2,
          dashArray: [0, 0, 5, 5]
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            color: '#fff', // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        markers: {
          size: 0,
          radius: 2,
          hover: {
            size: 3,
            sizeOffset: 3
          }
        },
        xaxis: {
          categories: [],
          tickAmount: 32,
          tooltip: { enabled: true }
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            return this.customTooltip({
              series,
              seriesIndex,
              dataPointIndex,
              w
            });
          }
        },
        yaxis: [],
        legend: {
          show: true,
          position: 'top',
          onItemClick: {
            toggleDataSeries: true
          },
          markers: {
            width: 16,
            height: 3,
            strokeColor: '#fff',
            fillColors: undefined,
            radius: 0,
            customHTML: function() {
              return `<style>
.apexcharts-legend-series:nth-child(1) .custom-marker,
.apexcharts-legend-series:nth-child(2) .custom-marker{
    display:none;
}
</style>
                          <div class="custom-marker" style="position:absolute; width:4px; height:6px; left:0;top:-2px;background:white"></div>
                        <div class="custom-marker" style="position:absolute; width:4px; height:6px; left:8px;top:-2px;background:white"></div>`;
            },
            onClick: undefined,
            offsetX: 0,
            offsetY: 0
          }
        },
        responsive: [
          {
            breakpoint: 769,
            options: {
              legend: {
                horizontalAlign: 'left'
              }
            }
          },
          {
            breakpoint: 426,
            options: {
              legend: {
                position: 'bottom'
              }
            }
          }
        ]
      },
      storePerformanceKpis: [
        'sls',
        'trn',
        'cn',
        'ap',
        'upt',
        'rov',
        'aip',
        'spt',
        'tpt',
        'is'
      ],
      staffPerformanceKpis: ['sl', 'sh', 'cs', 'se'],
      storeOutsideTrendKpis: ['pb', 'trf', 'cr'],
      storeInsideTrendKpis: [
        'dw',
        //"engagementrate",
        //"bouncerate",
        'maxoccupancy',
        'minoccupancy',
        'avgoccupancy',
        'avgoccupancy%',
        'numberofalerts',
        'numberofrequests',
        'requesttakenperalert',
        'timebeforetakingrequest',
        'trafficinline',
        'avgusersinline',
        'avgwaitingtime'
      ],
      fittingroomperformanceKpis: ['frvisits']
    };
  },

  computed: {
    ...mapGetters('filter', [
      'getStoreCodesList',
      'getDaysOfWeekList',
      'getStartingPeriod',
      'getEndingCompared',
      'getStartingCompared',
      'getEndingPeriod'
    ]),
    ...mapState('comparison', ['kpiAnalysis', 'kpiAnalysisType']),
    ...mapState('weather', ['weather', 'weatherCompare', 'citiesList']),
    ...mapState('detailedReport', ['kpiLineChart']),
    ...mapState('user', ['kpiPreferences', 'kpiListja', 'locale']),
    storePerformance() {
      return this.storePerformanceKpis
        .map(key => {
          let kpi;
          if (this.$i18n.locale == 'ja')
            kpi = this.kpiListja.find(k => k.metricKey === key);
          else kpi = this.kpiPreferences.find(k => k.metricKey === key);
          if (kpi) {
            return {
              tooltip: kpi?.explanation,
              label: kpi?.title,
              visibility: kpi?.visibility,
              code: key
            };
          }
        })
        .filter(k => {
          return k;
        });
    },
    staffPerformance() {
      return this.staffPerformanceKpis
        .map(key => {
          let kpi;
          if (this.$i18n.locale == 'ja')
            kpi = this.kpiListja.find(k => k.metricKey === key);
          else kpi = this.kpiPreferences.find(k => k.metricKey === key);
          if (kpi) {
            return {
              tooltip: kpi?.explanation,
              label: kpi?.title,
              visibility: kpi?.visibility,
              code: key
            };
          }
        })
        .filter(k => {
          return k;
        });
    },
    storeOutsideTrend() {
      return this.storeOutsideTrendKpis
        .map(key => {
          let kpi;
          if (this.$i18n.locale == 'ja')
            kpi = this.kpiListja.find(k => k.metricKey === key);
          else kpi = this.kpiPreferences.find(k => k.metricKey === key);
          if (kpi) {
            return {
              tooltip: kpi?.explanation,
              label: kpi?.title,
              visibility: kpi?.visibility,
              code: key
            };
          }
        })
        .filter(k => {
          return k;
        });
    },
    storeInsideTrend() {
      return this.storeInsideTrendKpis
        .map(key => {
          //const kpi = this.kpiPreferences.find(k => k.metricKey === key);
          let kpi;
          if (this.$i18n.locale == 'ja')
            kpi = this.kpiListja.find(k => k.metricKey === key);
          else kpi = this.kpiPreferences.find(k => k.metricKey === key);
          if (kpi) {
            return {
              tooltip: kpi?.explanation,
              label: kpi?.title,
              visibility: kpi?.visibility,
              code: key
            };
          }
        })
        .filter(k => {
          return k;
        });
    },
    fittingroomperformance() {
      return this.fittingroomperformanceKpis
        .map(key => {
          //  const kpi = this.kpiPreferences.find(k => k.metricKey === key);
          let kpi;
          if (this.$i18n.locale == 'ja')
            kpi = this.kpiListja.find(k => k.metricKey === key);
          else kpi = this.kpiPreferences.find(k => k.metricKey === key);
          if (kpi) {
            return {
              tooltip: kpi?.explanation,
              label: kpi?.title,
              visibility: kpi?.visibility,
              code: key
            };
          }
        })
        .filter(k => {
          return k;
        });
    },
    getDayDiff() {
      const startDate = moment(this.getStartingPeriod);
      const endDate = moment(this.getEndingCompared);
      const diff = startDate.diff(endDate, 'days');
      return Math.abs(diff);
    }
  }, // computed
  watch: {
    async weather() {
      await this.addWeather();
    },
    async weatherCompare() {
      await this.addWeather();
    },

    selectedKPI(val) {
      if (val !== this.selectedkpi) {
        this.setselectedkpi({ kpi: val });
      }
    },
    compareKPI(val) {
      if (val !== this.comparekpi) {
        this.setcomparekpi({ kpi: val });
      }
    },
    async analysisType(newValue) {
      this.setAnalysisType(newValue);
      await this.loadData();
      await this.setChartOptions();
    },
    comparekpi(newValue, oldValue) {
      this.compareKPI = newValue;
      this.loadData();
    },
    selectedkpi(newValue, oldValue) {
      this.selectedKPI = newValue;
      this.loadData();
    },
    kpiCategories: async function() {
      this.chartOptions.xaxis.categories = this.kpiCategories;
    }
  },
  created() {
    const kpiSelected = localStorage.getItem('selectedKPIdetailedReport');
    const kpiCompare = localStorage.getItem('compareKPIdetailedReport');
    if (kpiSelected && kpiCompare) {
      this.selectedKPI = kpiSelected;
      this.compareKPI = kpiCompare;
    } else {
      this.selectedKPI = 'sls';
      this.compareKPI = 'trf';
    }
  },
  mounted() {
    this.analysisType = this.kpiAnalysisType;
    this.chartOptions.chart.toolbar = {
      ...this.chartOptions.chart.toolbar,
      export: {
        svg: {
          filename: `${this.$t('KpiAnalysis.kpi_analysis')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}  ${this.$t(
            'KpiAnalysis.compared'
          )}꞉ ${this.getStartingCompared}˷${this.getEndingCompared}`
        },
        png: {
          filename: `${this.$t('KpiAnalysis.kpi_analysis')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}  ${this.$t(
            'KpiAnalysis.compared'
          )}꞉ ${this.getStartingCompared}˷${this.getEndingCompared}`
        },
        csv: {
          filename: `${this.$t('KpiAnalysis.kpi_analysis')} ${this.$t(
            'KpiAnalysis.selected'
          )}꞉ ${this.getStartingPeriod}˷${this.getEndingPeriod}  ${this.$t(
            'KpiAnalysis.compared'
          )}꞉ ${this.getStartingCompared}˷${this.getEndingCompared}`
        }
      }
    };
    this.loadData();
  },
  methods: {
    ...mapActions('comparison', ['fetchKPIAnalysis2']),
    ...mapMutations('comparison', ['setAnalysisType']),
    getMonthly(kpi) {
      const findDate = [
        ...this.weather,
        ...(this.weatherCompare ? this.weatherCompare : [])
      ];
      const monthData = [];
      findDate.forEach(obj => {
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            const cusObj = {};
            const monthName = moment(key).format('MMMM');
            if (monthName === kpi.timelineLabel) {
              cusObj[key] = obj[key];
              monthData.push(cusObj);
            }
          }
        }
      });
      const calculateAvg = () => {
        // if(weatherData.length >= weatherDataC.length){
        var tempObj = { temp: 0, tempMin: 0, tempMax: 0 };
        var myObj = {};
        var iconCounts = {};
        var descriptionCounts = {};
        monthData.forEach(key => {
          if (!key) return [];
          for (const obj of Object.values(key)) {
            tempObj.temp += obj.temp;
            tempObj.tempMax += obj.tempMax;
            tempObj.tempMin += obj.tempMin;
            // Count occurrences of icons and descriptions
            iconCounts[obj.icon] = (iconCounts[obj.icon] || 0) + 1;
            descriptionCounts[obj.description] =
              (descriptionCounts[obj.description] || 0) + 1;
          }
        });
        // // Find the most common icon and description
        const mostCommonIcon =
          Object.keys(iconCounts).length &&
          Object.keys(iconCounts).reduce((a, b) =>
            iconCounts[a] > iconCounts[b] ? a : b
          );
        // const mostCommonDescription = Object.keys(descriptionCounts).reduce((a, b) => (descriptionCounts[a] > descriptionCounts[b] ? a : b));
        // Calculate average temperature values
        tempObj.temp = tempObj.temp / monthData.length;
        tempObj.tempMax = tempObj.tempMax / monthData.length;
        tempObj.tempMin = tempObj.tempMin / monthData.length;
        // icons
        tempObj.icon = mostCommonIcon || '';
        // tempObj.description = mostCommonDescription
        myObj[kpi.timelineLabel] = tempObj;
        return myObj;
      };
      return calculateAvg();
    },
    getWeekly(kpi) {
      var copyStart = kpi.timelineLabel.indexOf('(');
      var copyEnd = kpi.timelineLabel.indexOf(')');
      var dates = kpi.timelineLabel.slice(copyStart + 1, copyEnd)?.split(' ');
      const dateFormat = 'YYYYMMDD';
      var start = moment(dates[0]).format(dateFormat);
      var end = moment(dates[2]).format(dateFormat);
      var current = start;
      var dateList = [];
      var weatherData = [];
      //
      while (current <= end) {
        dateList.push(moment(current).format('YYYY-MM-DD'));
        current = moment(current, dateFormat)
          .add(1, 'day')
          .format(dateFormat);
      }
      // find
      dateList?.forEach(key => {
        var findDate = [
          ...this.weather,
          ...(this.weatherCompare ? this.weatherCompare : [])
        ].find(v => v[key]);
        var obj = {};
        if (findDate) {
          obj[key] = findDate[key];
          weatherData.push(obj);
        }
      });
      //
      const calculateAvg = () => {
        // if(weatherData.length >= weatherDataC.length){
        var tempObj = { temp: 0, tempMin: 0, tempMax: 0 };
        var myObj = {};
        var iconCounts = {};
        var descriptionCounts = {};
        weatherData.forEach(key => {
          if (!key) return [];
          for (const obj of Object.values(key)) {
            tempObj.temp += obj.temp;
            tempObj.tempMax += obj.tempMax;
            tempObj.tempMin += obj.tempMin;
            // Count occurrences of icons and descriptions
            iconCounts[obj.icon] = (iconCounts[obj.icon] || 0) + 1;
            descriptionCounts[obj.description] =
              (descriptionCounts[obj.description] || 0) + 1;
          }
        });
        // // Find the most common icon and description
        const mostCommonIcon =
          Object.keys(iconCounts).length &&
          Object.keys(iconCounts).reduce((a, b) =>
            iconCounts[a] > iconCounts[b] ? a : b
          );
        // const mostCommonDescription = Object.keys(descriptionCounts).reduce((a, b) => (descriptionCounts[a] > descriptionCounts[b] ? a : b));
        // Calculate average temperature values
        tempObj.temp = tempObj.temp / 7;
        tempObj.tempMax = tempObj.tempMax / 7;
        tempObj.tempMin = tempObj.tempMin / 7;
        // icons
        tempObj.icon = mostCommonIcon || '';
        // tempObj.description = mostCommonDescription
        myObj[kpi.timelineLabel] = tempObj;
        return myObj;
      };
      return calculateAvg();
    },
    // Loads data and update chart
    async loadData() {
      try {
        this.loading = true;
     
        await this.fetchKPIAnalysis2({
          startDate: this.getStartingPeriod,
          endDate: this.getEndingPeriod,
          compareStartDate: this.getStartingCompared,
          compareEndDate: this.getEndingCompared,
          storeCodes: this.getStoreCodesList,
          selectedKPI: this.selectedKPI,
          compareKPI: this.compareKPI,
          type: this.kpiAnalysisType,
          daysOfWeek: this.getDaysOfWeekList
        });
         if (this.kpiAnalysisType === 'hourly') {
          const endDate = moment(this.getEndingPeriod);
          const endDateComp = moment(this.getEndingCompared);
          const hourlyWeather = await this.weatherHourly(endDate);
          const hourlyWeatherC = await this.weatherHourly(endDateComp);
          this.hourlyWeather = hourlyWeather?.weather;
          this.hourlyWeatherC = hourlyWeatherC?.weather;
        }
        await this.addWeather();
        // this.loading = false;
      } catch (err) {
        //        this.$q.notify({
        //          type: 'negative',
        //         position: 'top',
        //          message: err
        //        });
      } finally {
        this.loading = false;
      }
    }, // loadData
    async weatherHourly(endDate) {
      var cityName = this.citiesList;
      const res = await weather(endDate, endDate, cityName);
      return res;
    },
    async addWeather() {
      this.weatherData = [];
      this.selectedIcon = [];
      this.minTemp = [];
      this.maxTemp = [];
      //
      this.selectedIcon2 = [];
      this.minTemp2 = [];
      this.maxTemp2 = [];
      const days = this.getDaysOfWeekList.split(',');
      this.kpiAnalysis.comparedKPITrend.selectedPeriod = this.kpiAnalysis.comparedKPITrend?.selectedPeriod
        .map(kpi => {
          if (this.kpiAnalysisType === 'daily') {
            if (
              days.includes(
                moment(kpi.timelineLabel)
                  .format('dddd')
                  .toLowerCase()
              )
            )
              return kpi;
          } else return kpi;
        })
        .filter(kpi => kpi);

      this.kpiAnalysis.selectedKPITrend.selectedPeriod = this.kpiAnalysis.selectedKPITrend?.selectedPeriod
        .map(kpi => {
          if (this.kpiAnalysisType === 'daily') {
            if (
              days.includes(
                moment(kpi.timelineLabel)
                  .format('dddd')
                  .toLowerCase()
              )
            )
              return kpi;
          } else return kpi;
        })
        .filter(kpi => kpi);
      this.kpiCategories = this.kpiAnalysis.selectedKPITrend?.selectedPeriod.map(
        kpi => {
          if (this.kpiAnalysisType === 'hourly') {
            kpi.timelineLabel = parseInt(kpi.timelineLabel);
            // kpi.timelineLabel = parseInt(kpi.timelineLabel?.slice(0,2))
          }
          var findWeather =
            this.kpiAnalysisType === 'monthly'
              ? this.getMonthly(kpi)
              : this.kpiAnalysisType === 'daily'
              ? this.weather?.find(v => v.hasOwnProperty(kpi.timelineLabel))
              : this.kpiAnalysisType === 'hourly'
              ? this.hourlyWeather[0]
              : this.getWeekly(kpi);
          this.weatherData = [
            ...this.weatherData,
            findWeather && findWeather[kpi.timelineLabel]?.temp
          ];
          this.selectedIcon = [
            ...this.selectedIcon,
            findWeather && findWeather[kpi.timelineLabel]?.icon
          ];
          this.minTemp = [
            ...this.minTemp,
            findWeather && findWeather[kpi.timelineLabel]?.tempMin?.toFixed(2)
          ];
          this.maxTemp = [
            ...this.maxTemp,
            findWeather && findWeather[kpi.timelineLabel]?.tempMax?.toFixed(2)
          ];
          //
          return this.getKpiLabel(
            kpi.timelineLabel,
            findWeather && findWeather[kpi.timelineLabel]?.icon,
            findWeather && findWeather[kpi.timelineLabel]?.tempMax?.toFixed(2),
            findWeather && findWeather[kpi.timelineLabel]?.tempMin?.toFixed(2),
            kpi.year
            // kpi.timelineLabel,
            // kpi.temp,
            // kpi.minTemp?.toFixed(1),
            // kpi.maxTemp?.toFixed(1)
          );
        }
      );
      this.kpiCategories2 = this.kpiAnalysis.selectedKPITrend.comparedPeriod.map(
        kpi => {
          if (this.kpiAnalysisType === 'hourly') {
            kpi.timelineLabel = parseInt(kpi.timelineLabel);
            // kpi.timelineLabel = parseInt(kpi.timelineLabel?.slice(0,2))
          }
          var findWeather =
            this.kpiAnalysisType === 'monthly'
              ? this.getMonthly(kpi)
              : this.kpiAnalysisType === 'daily'
              ? this.weatherCompare?.find(v =>
                  v.hasOwnProperty(kpi.timelineLabel)
                )
              : this.kpiAnalysisType === 'hourly'
              ? this.hourlyWeatherC[0]
              : this.getWeekly(kpi);
          this.weatherData2 = [
            ...this.weatherData2,
            findWeather && findWeather[kpi.timelineLabel]?.temp
          ];
          this.selectedIcon2 = [
            ...this.selectedIcon2,
            findWeather && findWeather[kpi.timelineLabel]?.icon
          ];
          this.minTemp2 = [
            ...this.minTemp2,
            findWeather && findWeather[kpi.timelineLabel]?.tempMin?.toFixed(2)
          ];
          this.maxTemp2 = [
            ...this.maxTemp2,
            findWeather && findWeather[kpi.timelineLabel]?.tempMax?.toFixed(2)
          ];
          //
          return this.getKpiLabel(
            kpi.timelineLabel,
            findWeather && findWeather[kpi.timelineLabel]?.icon,
            findWeather && findWeather[kpi.timelineLabel]?.tempMax?.toFixed(2),
            findWeather && findWeather[kpi.timelineLabel]?.tempMin?.toFixed(2),
            kpi.year
          );
        }
      );
      await this.setSeriesData();
      await this.setChartOptions();
    },
    getLabel(value) {
      let name = this.kpiPreferences.find(val => val.metricKey === value);
      return name ? name.title : '';
    },
    setSeriesData() {
      const {
        kpiAnalysis: { comparedKPITrend, selectedKPITrend }
      } = this;
      let selectedKPIselectPeriod = {
        name:
          this.getLabel(this.selectedKPI) +
          ' ' +
          this.$t('KpiAnalysis.selected_period'),
        type: 'line',
        data: selectedKPITrend.selectedPeriod.map(kpi => kpi.metricValue),
        formattedData: selectedKPITrend.selectedPeriod.map(
          kpi => kpi.formattedMetricValue
        )
      };
      let selectedKPIcomparePeriod = {
        name:
          this.getLabel(this.selectedKPI) +
          ' ' +
          this.$t('KpiAnalysis.compared_period'),
        type: 'line',
        data: selectedKPITrend.comparedPeriod.map(kpi => kpi.metricValue),
        formattedData: selectedKPITrend.comparedPeriod.map(
          kpi => kpi.formattedMetricValue
        )
      };

      let compareKPIselectPeriod = {
        name:
          this.getLabel(this.compareKPI) +
          ' ' +
          this.$t('KpiAnalysis.selected_period'),
        data: comparedKPITrend.selectedPeriod.map(kpi => kpi.metricValue),
        type: 'area',
        formattedData: comparedKPITrend.selectedPeriod.map(
          kpi => kpi.formattedMetricValue
        )
      };

      let compareKPIcomparePeriod = {
        name:
          this.getLabel(this.compareKPI) +
          ' ' +
          this.$t('KpiAnalysis.compared_period'),
        type: 'line',
        data: comparedKPITrend.comparedPeriod.map(kpi => kpi.metricValue),
        formattedData: comparedKPITrend.comparedPeriod.map(
          kpi => kpi.formattedMetricValue
        )
      };
      this.series = [
        selectedKPIselectPeriod,
        compareKPIselectPeriod,
        selectedKPIcomparePeriod,
        compareKPIcomparePeriod
      ];
    },
    getKpiLabel(val, weather, min, max, year) {
      // const formattedDate = moment(val).locale('en').format("MMM DD");
      const lang = this.$store.state.user.locale;
      if (this.analysisType === 'daily') {
        const formattedDate = moment(val)
          .locale(lang)
          .format('ll');
        // if (this.getDayDiff < 7) {
        // if (this.getDayDiff < 7 && this.getStoreCodesList.split(",").length === 1) {
        return weather
          ? [
              formattedDate +
                ` (${this.$t(this.getFullDay(new Date(val).getDay()))})`,
              `${weatherObj[weather]} ${max}°C`,
              `${min}°C`
            ]
          : [
              formattedDate +
                ` (${this.$t(this.getFullDay(new Date(val).getDay()))})`,
              ``,
              ``
            ];
        // } else {
        //   return (
        //     val +
        //     `${
        //       this.analysisType === "daily"
        //         ? `(${this.$t(this.getFullDay(new Date(val).getDay()))})`
        //         : ""
        //     }`
        //   );
        // }
      } else if (this.analysisType === 'weekly') {
        if (val === 'null' || val === 'undefined') {
          return '-';
        } else {
          let stringWeek = this.formatedString(val, 'll');
          return stringWeek
            .replace('wk-', this.$t('FilterBar.week') + ' ')
            .replace('week', this.$t('FilterBar.week'));
        }
      } else if (this.analysisType === 'monthly') {
        return lang === 'ja' ? year + '年' +this.$t(`FilterBar.${val}`)  : this.$t(`FilterBar.${val}`) + ' ' + year;
      } else {
        return val ? val : '-';
      }
    },
    customTooltip({ series, seriesIndex, dataPointIndex, w }) {
      return `
        <q-card class="tooltip-container kpi-analysis-tooltip flex column q-pa-md items-center">
          <div class="flex justify-between items-center">
            ${
              this.getStoreCodesList.split(',').length &&
              this.analysisType === 'daily'
                ? this.kpiCategories[dataPointIndex]
                  ? Array.isArray(this.kpiCategories[dataPointIndex])
                    ? `<div>${this.kpiCategories[dataPointIndex]
                        .join(' ')
                        .toString()}</div>`
                    : `<div>${this.kpiCategories[dataPointIndex]}</div>`
                  : '-'
                : ''
            }
            ${
              this.getStoreCodesList.split(',').length &&
              this.analysisType !== 'daily'
                ? `<div class="flex justify-between">
                    <div class="flex>
                        <div class="">
                        ${
                          weatherObj[this.selectedIcon[dataPointIndex]]
                            ? weatherObj[this.selectedIcon[dataPointIndex]]
                            : ''
                        }
                        </div>
                        <div class="text-weight-bold q-mx-sm">${
                          this.maxTemp[dataPointIndex] &&
                          this.maxTemp[dataPointIndex] != 'NaN'
                            ? this.maxTemp[dataPointIndex] + '°C'
                            : ''
                        }</div>
                        <div class="q-mr-sm">${
                          this.minTemp[dataPointIndex] &&
                          this.minTemp[dataPointIndex] != 'NaN'
                            ? this.minTemp[dataPointIndex] + '°C'
                            : ''
                        }</div>
                    </div>
                    <div>${this.kpiCategories[dataPointIndex]}</div>
                  </div>`
                : ''
            }
          </div>
          <div class="flex items-center tooltip-title">
            <div
              class="tooltip-title-color"
              style="background:${w.config.colors[0]}"
            ></div>
            <div>
              ${w.globals.seriesNames[0] ? w.globals.seriesNames[0] : '-'} :
            </div>
            <div class="q-pl-md">
              ${
                this.series[0]['formattedData'][dataPointIndex]
                  ? this.series[0]['formattedData'][dataPointIndex]
                  : 0
              }
            </div>
          </div>
          <div class="flex items-center tooltip-title">
            <div
              class="tooltip-title-color"
              style="background:${w.config.colors[1]}"
            ></div>
            <div>${w.globals.seriesNames[1]}:</div>
            <div class="q-pl-md">
              ${
                this.series[1]['formattedData'][dataPointIndex]
                  ? this.series[1]['formattedData'][dataPointIndex]
                  : 0
              }
            </div>
          </div>
          <div class="q-mt-md flex justify-between items-center">
            ${
              this.getStoreCodesList.split(',').length &&
              this.analysisType === 'daily'
                ? this.kpiCategories2[dataPointIndex]
                  ? Array.isArray(this.kpiCategories2[dataPointIndex])
                    ? `<div>${this.kpiCategories2[dataPointIndex]
                        .join(' ')
                        .toString()}</div>`
                    : `<div>${this.kpiCategories2[dataPointIndex]}</div>`
                  : '-'
                : ''
            }
            ${
              this.getStoreCodesList.split(',').length &&
              this.analysisType !== 'daily'
                ? `<div class="flex justify-between">
                    <div class="flex>
                      <div class="">
                      ${
                        weatherObj[this.selectedIcon2[dataPointIndex]]
                          ? weatherObj[this.selectedIcon2[dataPointIndex]]
                          : ''
                      }
                      </div>
                      <div class="text-weight-bold q-mx-sm">${
                        this.maxTemp2[dataPointIndex] &&
                        this.maxTemp2[dataPointIndex] != 'NaN'
                          ? this.maxTemp2[dataPointIndex] + '°C'
                          : ''
                      }</div>
                      <div class="q-mr-sm">${
                        this.minTemp2[dataPointIndex] &&
                        this.minTemp2[dataPointIndex] != 'NaN'
                          ? this.minTemp2[dataPointIndex] + '°C'
                          : ''
                      }</div>
                    </div>
                  <div>${
                    this.kpiCategories2[dataPointIndex]
                      ? this.kpiCategories2[dataPointIndex]
                      : ''
                  }</div>
                  </div>`
                : ''
            }
          </div>
          <div class="flex items-center tooltip-title">
            <div
              class="tooltip-title-color dashed"
              style="background:${w.config.colors[2]}"
            ></div>
            <div>${w.globals.seriesNames[2]}:</div>
            <div class="q-pl-md">
              ${
                this.series[2]['formattedData'][dataPointIndex]
                  ? this.series[2]['formattedData'][dataPointIndex]
                  : 0
              }
            </div>
          </div>
          <div class="flex items-center tooltip-title">
            <div
              class="tooltip-title-color dashed"
              style="background:${w.config.colors[3]}"
            ></div>
            <div>${w.globals.seriesNames[3]}:</div>
            <div class="q-pl-md">
              ${
                this.series[3]['formattedData'][dataPointIndex]
                  ? this.series[3]['formattedData'][dataPointIndex]
                  : 0
              }
            </div>
          </div>
        </q-card>`;
    },
    getFullDay(val) {
      if (val === 0) {
        return this.$t('Date.Sun');
      }
      if (val === 1) {
        return this.$t('Date.Mon');
      }
      if (val === 2) {
        return this.$t('Date.Tue');
      }
      if (val === 3) {
        return this.$t('Date.Wed');
      }
      if (val === 4) {
        return this.$t('Date.Thu');
      }
      if (val === 5) {
        return this.$t('Date.Fri');
      }
      if (val === 6) {
        return this.$t('Date.Sat');
      }
    },
    setChartOptions() {
      let newCategories = [];
      let lang = this.$store.state.user.locale;
      let kpiCategory = JSON.parse(JSON.stringify(this.kpiCategories));
      let kpiCategory2 = JSON.parse(JSON.stringify(this.kpiCategories2));
      if (this.analysisType === 'daily' && this.getDayDiff < 7) {
        kpiCategory.forEach((cat1, index) => {
          let cat2 = kpiCategory2[index];
          if (lang === 'ja') {
            cat1[0] = moment(cat1[0], 'YYYY年M月D日')
              .locale(lang)
              .format(lang == 'ja' ? 'M月DD日' : 'MMM DD');
            cat2[0] = moment(cat2[0], 'YYYY年M月D日')
              .locale(lang)
              .format(lang == 'ja' ? 'M月DD日' : 'MMM DD');
          } else {
            cat1[0] =
              moment(cat1[0])
                .locale(lang)
                .format(lang == 'ja' ? 'M月DD日' : 'MMM DD') || '';
            cat2[0] =
              moment(cat2[0])
                .locale(lang)
                .format(lang == 'ja' ? 'M月DD日' : 'MMM DD') || '';
          }
          newCategories = kpiCategory.map(v => v[0]);
        });
      } else if (this.analysisType === 'weekly') {
        newCategories = kpiCategory.map(val => {
          let stringWeek = this.formatedString(
            val,
            lang == 'ja' ? 'M月DD日' : 'MMM DD'
          );
          return stringWeek
            .replace('wk-', this.$t('FilterBar.week') + ' ')
            .replace('week', this.$t('FilterBar.week'));
        });
      } else {
        if (this.analysisType === 'daily') {
          var ch = lang === 'ja' ? 'YYYY年M月D日' : '';
          newCategories = kpiCategory.map(v =>
            moment(v[0], ch)
              .locale(lang)
              .format(lang == 'ja' ? 'M月DD日' : 'MMM DD')
          );
        } else {
          newCategories = kpiCategory.map(v => v);
        }
      }
      const filter = newCategories.map(v => {
        var ch = lang === 'ja' ? 'YYYY年M月D日' : 'MMMM YYYY';
        const date = moment(v, ch).locale(lang);
        const abbreviatedMonth = date.format(lang == 'ja' ?'M月': 'MMM');
        return abbreviatedMonth;
      });
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          categories: this.analysisType == 'monthly' ? filter : newCategories,
          tickAmount: 32
        }
      };
      this.setYaxisVals();
    },
    formatedString(val, frmt = 'MMM DD') {
      let lang = this.$store.state.user.locale;
      let findStart = val.indexOf('(');
      let findEnd = val.indexOf(')');
      let weekString = val.slice(0, findStart);
      let dateString = val.slice(findStart + 1, findEnd);
      let datesWeek = dateString.split(' - ');
      let dateStartW =
        lang === 'ja'
          ? moment(datesWeek[0], 'YYYY年M月D日')
              .locale(lang)
              .format(frmt)
          : moment(datesWeek[0])
              .locale(lang)
              .format(frmt);
      let dateEndW =
        lang === 'ja'
          ? moment(datesWeek[1], 'YYYY年M月D日')
              .locale(lang)
              .format(frmt)
          : moment(datesWeek[1])
              .locale(lang)
              .format(frmt);
      let stringWeek = `${weekString} (${dateStartW} - ${dateEndW})`;
      return stringWeek;
    },
    setYaxisVals() {
      this.chartOptions = {
        ...this.chartOptions,
        yaxis: [
          {
            seriesName:
              this.getLabel(this.selectedKPI) +
              ' ' +
              this.$t('KpiAnalysis.selected_period'),
            decimalsInFloat: 0,
            show: this.axisVisibility.index1,
            labels: {
              formatter: value => {
                return (
                  formatYAxis(value?.toFixed(0)) +
                  this.$t(`unit.${this.selectedKPI}Unit`)
                );
              }
            }
          },
          {
            seriesName:
              this.getLabel(this.compareKPI) +
              ' ' +
              this.$t('KpiAnalysis.compared_period'),
            decimalsInFloat: 0,
            opposite: true,
            show: this.axisVisibility.index2,
            labels: {
              formatter: value => {
                return (
                  formatYAxis(value?.toFixed(0)) +
                  this.$t(`unit.${this.compareKPI}Unit`)
                );
              }
            }
          },
          {
            seriesName:
              this.getLabel(this.selectedKPI) +
              ' ' +
              this.$t('KpiAnalysis.selected_period'),
            decimalsInFloat: 0,
            show: this.axisVisibility.index3,
            labels: {
              formatter: value => {
                return (
                  formatYAxis(value?.toFixed(0)) +
                  this.$t(`unit.${this.compareKPI}Unit`)
                );
              }
            }
          },
          {
            seriesName:
              this.getLabel(this.compareKPI) +
              ' ' +
              this.$t('KpiAnalysis.compared_period'),
            opposite: true,
            decimalsInFloat: 0,
            axisTicks: {
              show: true
            },
            show: this.axisVisibility.index4,
            labels: {
              formatter: value => {
                return (
                  formatYAxis(value?.toFixed(0)) +
                  this.$t(`unit.${this.compareKPI}Unit`)
                );
              }
            }
          }
        ]
      };
    }
  }
};
