import { APIGetKpisPlainDataFidaV2,
  APIGetReportingSumByLocationDataFidaV2,
  APIGetKpisPlainDataFida } from '@/api/fidaApi';
  import { getKpiNameByKey } from '@/store/modules/exportFida';
  import convertor from '@/api/apiDataManagers/fidaToOldConvertor';
import store from '@/store';
import {compatilbleNewFormat} from '@/api/apiDataManagers/demographic_conv'

import { getSignsLeftSide,getSignsRightSide } from '@/util/replaceFidaKpi';
import {formatIntDate,getDateDifference, getStoreMinAndMaxHourForDatesAndStores, getListOfDates, getEntrancesWithStores} from '@/util/utilFidaFunctions';
import { addSign } from '@/util/replaceFidaKpi';

export async function GetReportingSumDataFidaV2(params: any) {//Hourly Report by store
delete  params.frequency;
  if (!params.kpiTypes || params.kpiTypes.length === 0) {
    throw new Error("Empty kpiTypes parameter");
  }

  var response: any;
  const callingKpis =  [...params.kpiTypes];

  response =  await APIGetKpisPlainDataFidaV2(params);

  if (response && response.data.response && response.data.response.kpis) {
    // console.log('response');
    // console.log(response.data.response.kpis);
    // const datesList = getListOfDates(params.startDate, params.endDate);
    // const storeCodesList = params.storeCodes.split(',');

    // const getStoreTimmings = getStoreMinAndMaxHourForDatesAndStores(datesList, storeCodesList);
    // const { minOpening, maxClosing } = getStoreTimmings;

    // const storesWithEntrances = getEntrancesWithStores(params.storeCodes);
  //   for (const key in storesWithEntrances) {
  //     if (storesWithEntrances.hasOwnProperty(key) && storesWithEntrances[key].entrances?.length === 0) {
  //         delete storesWithEntrances[key];
  //     }
  // }
  const data = response.data.response.kpis;
  const kpis = params.kpiTypes;
 
  const newData = data.map(obj => {
    const newObj = {...obj};
    
Object.keys(newObj).forEach(key => {
  if (!kpis.includes(key) && key !== '_id') {
      delete newObj[key];
  }
});

    kpis.forEach(key => {
      if(!kpis.includes(key))
      {
        delete newObj[key];
      }
      else
      if (!newObj.hasOwnProperty(key)) 
      {
        if(key =='trf')
        {
          newObj[key] = 0;
          newObj.trf = {
            dumy: { in: 0, trf: 0 }
          };
        }
        else
          newObj[key] = 0; // or assign any default value here
      }
    });
    return newObj;
  });

    
    const sortedData = newData.map(obj => {
      const newObj = {};
      Object.keys(obj)
        .sort((a, b) => kpis.indexOf(a) - kpis.indexOf(b)) // Custom sorting function based on orderKeys
        .forEach(key => {
          newObj[key] = obj[key];
        });
      return newObj;
    });
    response.data.response.kpis = sortedData;
    console.log(response.data.response.kpis);

    return response.data.response;
  }
  return [];

}
//by location Start
export async function GetReportingSumDataByLocationFidaV2LocationRep(params: any) {//Comparison and Location

  delete params.page;

  delete params.isReport;

 if (!params.kpiTypes || params.kpiTypes.length === 0) {
   throw new Error("Empty kpiTypes parameter");
 }
 var response: any;
 const callingKpis =  [...params.kpiTypes];
 params.kpiTypes = missingKpiKey(params.kpiTypes);
 response =  await APIGetReportingSumByLocationDataFidaV2(params);

 var allStoresArea = 0;
 if(params.kpiTypes.includes('spt') || params.kpiTypes.includes('tpt'))
 {
  allStoresArea = getAllStoresArea(params.storeCodes);
 }
 response.data.response.kpis = missingTsubu(response.data.response.kpis,params.kpiTypes,allStoresArea);
 response.data.response.kpis = missinZeroDataAll(response.data.response.kpis, callingKpis, params.storeCodes);
  if(response.data.response.kpisCompare)
  {
  response.data.response.kpisCompare = missingTsubu(response.data.response.kpisCompare,params.kpiTypes,allStoresArea);
  response.data.response.kpisCompare = missinZeroDataAll(response.data.response.kpisCompare, callingKpis, params.storeCodes);
  }
 var finalResut = response.data.response;
 return finalResut;
}
function missingTsubu(response,kpis,allStoresAreas) {
  if(response && response.length > 0)
  {
    response.forEach((data) => {


      if(kpis.includes('spt') || kpis.includes('tpt') )
      {

      if(kpis.includes('spt'))
        data.spt =Math.round(data.sls / allStoresAreas);

      if(kpis.includes('tpt'))
        data.tpt = Math.round((data.trf / allStoresAreas)*100)/100;

      }
    });
  }
  return response;
}
//by location End

//Start Comparison
export async function GetReportingSumDataByLocationFidaV2(params: any) {//Comparison
  const isReport = params.isReport;
  const page = params.page;
  delete params.page;

  delete params.isReport;

 if (!params.kpiTypes || params.kpiTypes.length === 0) {
   throw new Error("Empty kpiTypes parameter");
 }
 var response: any;
 const callingKpis =  [...params.kpiTypes];
 params.kpiTypes = missingKpiKey(params.kpiTypes);

 response =  await APIGetReportingSumByLocationDataFidaV2(params);
 var allStoresArea = 1;
 if(params.kpiTypes.includes('spt') || params.kpiTypes.includes('tpt'))
 {
  allStoresArea = getAllStoresArea(params.storeCodes);
 }

 const numOfStores = 1;//params.storeCodes.length;
 const datesDiff = 1;

 if(response.data.response.kpis)
 {
  response.data.response.kpis = missingKpi(response.data.response.kpis,params.kpiTypes,params.reportType,params.storeCodes,allStoresArea,page,numOfStores,datesDiff);
  response.data.response.kpis = missinZeroDataAll(response.data.response.kpis, callingKpis, params.storeCodes);

}
 if(response.data.response.kpisCompare)
 {
  response.data.response.kpisCompare = missingKpi(response.data.response.kpisCompare,params.kpiTypes,params.reportType,params.storeCodes,allStoresArea,page,numOfStores,datesDiff);
  response.data.response.kpisCompare = missinZeroDataAll(response.data.response.kpisCompare, callingKpis, params.storeCodes);

}
 var finalResut = response.data.response;




 return finalResut;
}
//End Comparison

export async function GetReportingSumDataByLocationFidaCompDetailV2(params: any) {//Detailed Report
  //var typeAnalysis = localStorage.getItem("typeAnalysis");

//  localStorage.setItem("typeAnalysis",null);
  const reportType = params.reportType;
  const weeklyKpiAnalysis = params.frequency;

     
  if(reportType == 'kpianalysis' && weeklyKpiAnalysis == 'weekly')
  {
    params.frequency = 'daily';
  }

  delete params.reportType;
  delete params.isReport;
  if (!params.kpiTypes || params.kpiTypes.length === 0) {
    throw new Error("Empty kpiTypes parameter");
  }
  params.kpiTypes = params.kpiTypes.split(',');

  var response: any;
  const callingKpis =  [...params.kpiTypes];
  params.kpiTypes = missingKpiKey(params.kpiTypes);
  response =  await APIGetReportingSumByLocationDataFidaV2(params);
  var allStoresArea = 0;
  if(params.kpiTypes.includes('spt') || params.kpiTypes.includes('tpt'))
  {
    allStoresArea = getAllStoresArea(params.storeCodes);
  }


  const numOfStores = params.storeCodes.length;
  const datesDiff = 1;
  var weekDayCount = 0;
  if(params.days?.length)
    {
      weekDayCount = params.days?.length;
    }
  if(response.data.response.kpis)
  {
    if(reportType == 'kpianalysis' && weeklyKpiAnalysis == 'weekly')
    {
      response.data.response.kpis = convertToWeekly(response.data.response.kpis,'kpis');
      params.frequency = 'weekly'
    }

  if(params.frequency =='daily' && reportType != 'kpianalysis' )
  {
    response.data.response.kpis = missinZeroDataByFrequency(response.data.response.kpis, callingKpis, params.startDate, params.endDate, params.storeCodes,params.frequency);
  } else if(params.frequency =='daily' && reportType == 'kpianalysis' && weekDayCount == 7 )
    {
      response.data.response.kpis = missinZeroDataByFrequency(response.data.response.kpis, callingKpis, params.startDate, params.endDate, params.storeCodes,params.frequency);
    }
  response.data.response.kpis = getSumOfKpi(response.data.response.kpis);
  response.data.response.kpis =  missingKpi(response.data.response.kpis,callingKpis,reportType,params.storeCodes,allStoresArea,'detailed',numOfStores,datesDiff);

  }

  if(response.data.response.kpisCompare)
  {
    if(reportType == 'kpianalysis' && weeklyKpiAnalysis == 'weekly')
    {
      response.data.response.kpisCompare = convertToWeekly(response.data.response.kpisCompare,'kpisCompare');
      params.frequency = 'weekly'
    }

    if(params.frequency =='daily' && reportType != 'kpianalysis')
    {
      response.data.response.kpisCompare = missinZeroDataByFrequency(response.data.response.kpisCompare, callingKpis, params.compareStartDate, params.compareEndDate, params.storeCodes,params.frequency);
    }
    else if(params.frequency =='daily' && reportType == 'kpianalysis' && weekDayCount == 7 )
      {
        response.data.response.kpisCompare = missinZeroDataByFrequency(response.data.response.kpisCompare, callingKpis, params.compareStartDate, params.compareEndDate, params.storeCodes,params.frequency);
      }
    response.data.response.kpisCompare = getSumOfKpi(response.data.response.kpisCompare);
    response.data.response.kpisCompare = missingKpi(response.data.response.kpisCompare,callingKpis,reportType,params.storeCodes,allStoresArea,'detailed',numOfStores,datesDiff);

  }
console.log('response.data.response Weekly',response.data.response);
 return response.data.response;;
}
//for export module start
 export async function GetReportingSumDataByLocationFidaV2OnlyExport(params: any) {//Export
  const isReport = params.isReport;
  const reportType = params.reportType;
  delete params.reportType;
  delete params.page;

  delete params.isReport;

 if (!params.kpiTypes || params.kpiTypes.length === 0) {
   throw new Error("Empty kpiTypes parameter");
 }

 var response: any;
 const callingKpis =  [...params.kpiTypes];
 params.kpiTypes = missingKpiKey(params.kpiTypes);
 response =  await APIGetReportingSumByLocationDataFidaV2(params);
 const sortingOrder = "sls,trf,trn,cn,ap,upt,rov,aip,spt,tpt,sl,sh,cs,se,pb,cr,dw";
 response.data.response.kpis = customSort(response.data.response.kpis, sortingOrder);

 const datesDifference = getDateDifference(params.startDate,params.endDate);

if(reportType == 'detailed' || reportType == 'summary')
{

  if(response.data.response.kpis)
  {

    response.data.response.kpis = missingKpiAllForExport(response.data.response.kpis,callingKpis,reportType,datesDifference);
    response.data.response.kpis = missinZeroDataAll(response.data.response.kpis, callingKpis, params.storeCodes);
  }
  if(response.data.response.kpisCompare)
  {
    response.data.response.kpisCompare = missingKpiAllForExport(response.data.response.kpisCompare,callingKpis,reportType,datesDifference);
    response.data.response.kpisCompare = missinZeroDataAll(response.data.response.kpisCompare, callingKpis, params.storeCodes);

  }
}

 else if(reportType == 'daily' || reportType =='AllStoresDaily')
 {
    if(response.data.response.kpis)
    {
      response.data.response.kpis = missingKpiForExport(response.data.response.kpis,callingKpis,reportType,params.storeCodes);
      response.data.response.kpis = missinZeroDataByFrequency(response.data.response.kpis, callingKpis, params.startDate, params.endDate, params.storeCodes,'daily');
    }
    if(response.data.response.kpisCompare)
    {
      response.data.response.kpisCompare = missingKpiForExport(response.data.response.kpisCompare,callingKpis,reportType,params.storeCodes);
      response.data.response.kpisCompare = missinZeroDataByFrequency(response.data.response.kpisCompare, callingKpis, params.compareStartDate, params.compareEndDate, params.storeCodes,'daily');
    }
 }
 else if(reportType == 'hourly' )
 {
  // const datesList = getListOfDates(params.startDate, params.endDate);
  // const getStoreTimmings = getStoreMinAndMaxHourForDatesAndStores(datesList, params.storeCodes);
  // const { minOpening, maxClosing } = getStoreTimmings;

    if(response.data.response.kpis)
    {
      response.data.response.kpis = missingKpiForExport(response.data.response.kpis,callingKpis,reportType,params.storeCodes);
    }
    if(response.data.response.kpisCompare)
    {
      response.data.response.kpisCompare = missingKpiForExport(response.data.response.kpisCompare,callingKpis,reportType,params.storeCodes);
    }
 }
 else
 {
  if(response.data.response.kpis)
  {
    response.data.response.kpis = missingKpiAllForExport(response.data.response.kpis,callingKpis,reportType,datesDifference);
  }
  if(response.data.response.kpisCompare)
  {
    response.data.response.kpisCompare = missingKpiAllForExport(response.data.response.kpisCompare,callingKpis,reportType,datesDifference);
  }
 }

 var finalResut = response.data.response;


 return finalResut;
}
function missinZeroDataAll(apiResponse, kpiTypes, storeCodes) {

  const dateStoreMap = {};

    // Create a mapping of store codes to quickly check for missing entries
    if(apiResponse && apiResponse.length > 0)
    {
      apiResponse.forEach(entry => {
          const storeCode = entry._id;
          dateStoreMap[storeCode] = true;
      });
    }

    const missingEntries = [];
    for (const storeCode of storeCodes) {

        if (!dateStoreMap[storeCode]) {
            const newEntry = {
                _id: storeCode,
            };
            kpiTypes.forEach(kpi => (newEntry[kpi] = 0));
            missingEntries.push(newEntry);
        }
    }
    return [...apiResponse, ...missingEntries];
}


function getWeekNumbers(startDate, endDate) {

  const start = new Date(startDate);
  const end = new Date(endDate);
  const weekNumbers = new Set();

  for (let current = start; current <= end; current.setDate(current.getDate() + 1)) {
      const weekNumber = calculateWeekNumber(current);
      weekNumbers.add(weekNumber);
  }

  return Array.from(weekNumbers);
}

function calculateWeekNumber(date) {
  const clonedDate:any = new Date(date);
  clonedDate.setHours(0, 0, 0, 0);
  clonedDate.setDate(clonedDate.getDate() + 4 - (clonedDate.getDay() || 7));

  const yearStart:any = new Date(clonedDate.getFullYear(), 0, 1);
  const weekNumber = Math.ceil(((clonedDate - yearStart) / 86400000 + 1) / 7);

  return weekNumber;
}
function missinZeroDataByFrequency(apiResponse, kpiTypes, startDate, endDate, storeCodes,type) {
  const dateStoreMap = {};
  const missingEntries = [];
  if( type =='daily')
  {
    apiResponse?.forEach(entry => {
        const [date, storeCode] = entry._id.split('_');
        if (!dateStoreMap[date]) {
            dateStoreMap[date] = [];
        }
        dateStoreMap[date].push(storeCode);
    });

    for (let date = startDate; date <= endDate; date = getNextDate(date)) {
        for (const storeCode of storeCodes) {
            const dateKey = date.replace(/-/g, '');
            if (!dateStoreMap[dateKey] || !dateStoreMap[dateKey].includes(storeCode)) {
                const newEntry = {
                    _id: `${dateKey}_${storeCode}`,
                };
                kpiTypes.forEach(kpi => (newEntry[kpi] = 0));
                missingEntries.push(newEntry);
            }
        }
    }
  }
  else //if( type =='weekly')
  {

    var weekDays;
    if( type =='weekly')
    {
      weekDays = getWeekNumbers(startDate, endDate);
    }
    apiResponse?.forEach(entry => {
      const [date, storeCode] = entry._id.split('_');
      if (!dateStoreMap[date]) {
          dateStoreMap[date] = [];
      }
      dateStoreMap[date].push(storeCode);
  });

  for (let week = 0; week < weekDays.length; week++) {
      for (const storeCode of storeCodes) {
          const dateKey = weekDays[week];
          if (!dateStoreMap[dateKey] || !dateStoreMap[dateKey].includes(storeCode)) {
              const newEntry = {
                  _id: `${dateKey}_${storeCode}`,
              };
              kpiTypes.forEach(kpi => (newEntry[kpi] = 0));
              missingEntries.push(newEntry);
          }
      }
  }
  }

  return [...apiResponse, ...missingEntries];
}


function getNextDate(date) {
  const currentDate = new Date(date);
  currentDate.setDate(currentDate.getDate() + 1);
  return currentDate.toISOString().slice(0, 10);
}

function customSort(arr, order) {
  const orderArray = order.split(',');

  return arr.map(obj => {
    const sortedObj = {};

    orderArray.forEach(key => {
      if (obj.hasOwnProperty(key)) {
        sortedObj[key] = obj[key];
        delete obj[key];
      }
    });

    // Add remaining keys that are not in the sorting order
    for (const remainingKey in obj) {
      if (obj.hasOwnProperty(remainingKey)) {
        sortedObj[remainingKey] = obj[remainingKey];
      }
    }

    return sortedObj;
  });
}





//for export module end
//by location End

export async function GetKpisPlainDataFidaForDemographics(params: any) {// demographics
  var response: any;
  // delete params.reportType
  response = await APIGetKpisPlainDataFida(params);
  // params.kpiTypes = ["dm"]
  // response =  await APIGetKpisPlainDataFidaV2(params);
  // response.data.response.kpis = compatilbleNewFormat(response)
  return response.data.response;
}

//Response Convertion for BoxPerformanceComparisonRadarAM.vue
interface ConvertedResponse {
  data: {
      startDate: string;
      endDate: string;
      compareStartDate: string;
      compareEndDate: string;
      kpis: Record<string, {
          metricKey: string;
          metricKeyShort: string;
          value: string;
          comparedValue: string;
          variation: string;
          variationPercentage: string;
          growthIndicator: number;
          variance: string;
          metricID: string;
      }>;
  }[];
}

export function convertApiResponse(apiResponse: any): ConvertedResponse {// for Comparison Radar chart
  ;
  const convertedData: ConvertedResponse = {
      data: []
  };

  const kpis: Record<string, any> = {};
  for (const key in apiResponse.response.kpis) {
    if(apiResponse.response.kpis[key].length != 0)
    {
      const kpi = apiResponse.response.kpis[key][0];

      const variationPercentage = (0).toFixed(2);
    //getSignsLeftSide(kpiName)+countValue.toLocaleString()+getSignsRightSide(kpiName);
      const value = `${getSignsLeftSide(key)}${kpi.totalCount.toLocaleString()}${getSignsRightSide(key)}`;
      const comparedValue = value;

      kpis[key] = {
          metricKey: key.charAt(0).toUpperCase() + key.slice(1),
          metricKeyShort: key.charAt(0).toUpperCase() + key.slice(1),
          value: value,
          comparedValue: comparedValue,
          variation: (0).toLocaleString(),
          variationPercentage: `${variationPercentage}%`,
          growthIndicator: 0,
          variance: `${variationPercentage}%`,
          metricID: key
      };
    }
    else
    {
      const variationPercentage2 = (0).toFixed(2);
      const valueKpi = '0'.toLocaleString();
    //getSignsLeftSide(kpiName)+countValue.toLocaleString()+getSignsRightSide(kpiName);
      const value2 = `${getSignsLeftSide(key)}${valueKpi}${getSignsRightSide(key)}`;
      kpis[key] = {
        metricKey: key.charAt(0).toUpperCase() + key.slice(1),
        metricKeyShort: key.charAt(0).toUpperCase() + key.slice(1),
        value: value2,
        comparedValue: value2,
        variation: (0).toLocaleString(),
        variationPercentage: `${variationPercentage2}%`,
        growthIndicator: 0,
        variance: `${variationPercentage2}%`,
        metricID: key
    };
    }
  }

  convertedData.data.push({
      startDate: apiResponse.startDate,
      endDate: apiResponse.endDate,
      compareStartDate: apiResponse.compareStartDate,
      compareEndDate: apiResponse.compareEndDate,
      kpis
  });

  return convertedData;
}

export function convertApiResponseForComparisonCharts(apiResponse, kpiKey, startDate,EndDate, type) {
  const selectedPeriod =processComparisonData(apiResponse, kpiKey, startDate,EndDate, type,'kpis');
  const compareKpis = processComparisonData(apiResponse, kpiKey, startDate,EndDate, type,'kpisCompare');
  // for hourly table pulse
  const selectedPeriodOther =processComparisonData(apiResponse, 'trf', startDate,EndDate, type,'kpis');
  const compareKpisOther = processComparisonData(apiResponse, 'trf', startDate,EndDate, type,'kpisCompare');
  //
  selectedPeriod?.sort((a, b) => a.timelineLabel.localeCompare(b.timelineLabel));
  if(compareKpis)
    compareKpis?.sort((a, b) => a.timelineLabel.localeCompare(b.timelineLabel));

  return {
    data: {
      selectedKPITrend: {
        metricKey: getKpiNameByKey(kpiKey),//kpiKey.charAt(0).toUpperCase() + kpiKey.slice(1), // Capitalize the first letter of the KPI key
        metricID: kpiKey,
        selectedPeriod:selectedPeriod?selectedPeriod:[],
        selectedPeriodOther:selectedPeriodOther?selectedPeriodOther:[]
      },
      comparedKPITrend: {
        metricKey: getKpiNameByKey(kpiKey),//kpiKey.charAt(0).toUpperCase() + kpiKey.slice(1), // Capitalize the first letter of the KPI key
        metricID: kpiKey,
        comparedPeriod: compareKpis?compareKpis:[],
        comparedPeriodOther: compareKpisOther?compareKpisOther:[]
      },
    },
  };
}
export function convertApiResponseForComparisonCharts2(apiResponse,apiResponseCompare, kpiKey,compareKPI, startDate,EndDate, type) {

  const selectedPeriod =processComparisonData2(apiResponse, kpiKey, startDate,EndDate, type,'kpis');
  const compareKpis = processComparisonData2(apiResponse, kpiKey, startDate,EndDate, type,'kpisCompare');
  console.log('selectedPeriodselectedPeriod',selectedPeriod);
  // Define the month order mapping
  const monthOrder = {
    "January": 1,
    "February": 2,
    "March": 3,
    "April": 4,
    "May": 5,
    "June": 6,
    "July": 7,
    "August": 8,
    "September": 9,
    "October": 10,
    "November": 11,
    "December": 12
  };
  if(type == "monthly")
  {
    selectedPeriod?.sort((a, b) => monthOrder[a.timelineLabel] - monthOrder[b.timelineLabel]);
    if(compareKpis)
    compareKpis?.sort((a, b) =>  monthOrder[a.timelineLabel] - monthOrder[b.timelineLabel]);//a.timelineLabel.localeCompare(b.timelineLabel));
  }
  else if(type == "weekly")
  {
    // selectedPeriod?.sort((a, b) => {
    //   const weekNumberA = a?.weeklySorter?a.weeklySorter:parseInt(a.timelineLabel.match(/Week (\d+)/)[1]);
    //   const weekNumberB = b?.weeklySorter?b.weeklySorter:parseInt(b.timelineLabel.match(/Week (\d+)/)[1]);
    //   return weekNumberA - weekNumberB;
    // });

    
    selectedPeriod?.sort((a, b) => {
      const weeklySorterA = a?.weeklySorter || parseInt(a.timelineLabel.match(/Week (\d+)/)[1]);
      const weeklySorterB = b?.weeklySorter || parseInt(b.timelineLabel.match(/Week (\d+)/)[1]);

      const weekA = parseInt(weeklySorterA.toString().slice(4)); // Extract the last 2 digits (week)
      const weekB = parseInt(weeklySorterB.toString().slice(4));
         
       return weekA - weekB;
    });
    selectedPeriod?.sort((a, b) => {
      const weeklySorterA = a?.weeklySorter || parseInt(a.timelineLabel.match(/Week (\d+)/)[1]);
      const weeklySorterB = b?.weeklySorter || parseInt(b.timelineLabel.match(/Week (\d+)/)[1]);
    
      const yearA = parseInt(weeklySorterA.toString().slice(0, 4)); // Extract the first 4 digits (year)
      const yearB = parseInt(weeklySorterB.toString().slice(0, 4));   
      return  yearB - yearA;
    });
    
    if (compareKpis) {
      // compareKpis?.sort((a, b) => {
      //   const weekNumberA = a?.weeklySorter?a.weeklySorter:parseInt(a.timelineLabel.match(/Week (\d+)/)[1]);
      //   const weekNumberB = b?.weeklySorter?b.weeklySorter:parseInt(b.timelineLabel.match(/Week (\d+)/)[1]);
      //   return weekNumberA - weekNumberB;
      // });

     
      compareKpis?.sort((a, b) => {
        const weeklySorterA = a?.weeklySorter || parseInt(a.timelineLabel.match(/Week (\d+)/)[1]);
        const weeklySorterB = b?.weeklySorter || parseInt(b.timelineLabel.match(/Week (\d+)/)[1]);
  
        const weekA = parseInt(weeklySorterA.toString().slice(4)); // Extract the last 2 digits (week)
        const weekB = parseInt(weeklySorterB.toString().slice(4));
           
         return weekA - weekB;
      });
      compareKpis?.sort((a, b) => {
        const weeklySorterA = a?.weeklySorter || parseInt(a.timelineLabel.match(/Week (\d+)/)[1]);
        const weeklySorterB = b?.weeklySorter || parseInt(b.timelineLabel.match(/Week (\d+)/)[1]);
      
        const yearA = parseInt(weeklySorterA.toString().slice(0, 4)); // Extract the first 4 digits (year)
        const yearB = parseInt(weeklySorterB.toString().slice(0, 4));   
        return yearB - yearA;
      });
    }

  }
  else
  {
    selectedPeriod?.sort((a, b) => a.timelineLabel.localeCompare(b.timelineLabel));
    if(compareKpis)
      compareKpis?.sort((a, b) => a.timelineLabel.localeCompare(b.timelineLabel));
  }

  const selectedPeriodComp =processComparisonData2(apiResponseCompare, compareKPI, startDate,EndDate, type,'kpis');
  const compareKpisComp = processComparisonData2(apiResponseCompare, compareKPI, startDate,EndDate, type,'kpisCompare');
  if(type == "monthly")
  {
    selectedPeriodComp?.sort((a, b) => monthOrder[a.timelineLabel] - monthOrder[b.timelineLabel]);
    if(compareKpisComp)
    compareKpisComp?.sort((a, b) => monthOrder[a.timelineLabel] - monthOrder[b.timelineLabel]);
  }
  else if(type == "weekly")
  {

    
    selectedPeriodComp?.sort((a, b) => {
      const weeklySorterA = a?.weeklySorter || parseInt(a.timelineLabel.match(/Week (\d+)/)[1]);
      const weeklySorterB = b?.weeklySorter || parseInt(b.timelineLabel.match(/Week (\d+)/)[1]);

      const weekA = parseInt(weeklySorterA.toString().slice(4)); // Extract the last 2 digits (week)
      const weekB = parseInt(weeklySorterB.toString().slice(4));
         
       return weekA - weekB;
    });
    selectedPeriodComp?.sort((a, b) => {
      const weeklySorterA = a?.weeklySorter || parseInt(a.timelineLabel.match(/Week (\d+)/)[1]);
      const weeklySorterB = b?.weeklySorter || parseInt(b.timelineLabel.match(/Week (\d+)/)[1]);
    
      const yearA = parseInt(weeklySorterA.toString().slice(0, 4)); // Extract the first 4 digits (year)
      const yearB = parseInt(weeklySorterB.toString().slice(0, 4));   
      return yearB - yearA;
    });
    // selectedPeriodComp?.sort((a, b) => {
    //   const weekNumberA = parseInt(a.timelineLabel.match(/Week (\d+)/)[1]);
    //   const weekNumberB = parseInt(b.timelineLabel.match(/Week (\d+)/)[1]);
    //   return weekNumberA - weekNumberB;
    // });

    if (compareKpisComp) {
      // compareKpisComp?.sort((a, b) => {
      //   const weekNumberA = parseInt(a.timelineLabel.match(/Week (\d+)/)[1]);
      //   const weekNumberB = parseInt(b.timelineLabel.match(/Week (\d+)/)[1]);
      //   return weekNumberA - weekNumberB;
      // });
      
      compareKpisComp?.sort((a, b) => {
        const weeklySorterA = a?.weeklySorter || parseInt(a.timelineLabel.match(/Week (\d+)/)[1]);
        const weeklySorterB = b?.weeklySorter || parseInt(b.timelineLabel.match(/Week (\d+)/)[1]);
  
        const weekA = parseInt(weeklySorterA.toString().slice(4)); // Extract the last 2 digits (week)
        const weekB = parseInt(weeklySorterB.toString().slice(4));
           
         return weekA - weekB;
      });
      compareKpisComp?.sort((a, b) => {
        const weeklySorterA = a?.weeklySorter || parseInt(a.timelineLabel.match(/Week (\d+)/)[1]);
        const weeklySorterB = b?.weeklySorter || parseInt(b.timelineLabel.match(/Week (\d+)/)[1]);
      
        const yearA = parseInt(weeklySorterA.toString().slice(0, 4)); // Extract the first 4 digits (year)
        const yearB = parseInt(weeklySorterB.toString().slice(0, 4));   
        return yearB - yearA;
      });
    }

  }
  else
  {
    selectedPeriodComp?.sort((a, b) => a.timelineLabel.localeCompare(b.timelineLabel));
    if(compareKpisComp)
    compareKpisComp?.sort((a, b) => a.timelineLabel.localeCompare(b.timelineLabel));
  }

  return {
    data: {
      selectedKPITrend: {
        metricKey: getKpiNameByKey(kpiKey),//kpiKey.charAt(0).toUpperCase() + kpiKey.slice(1), // Capitalize the first letter of the KPI key
        metricID: kpiKey,
        selectedPeriod:selectedPeriod?selectedPeriod:[],
        comparedPeriod: compareKpis?compareKpis:[]
      },
      comparedKPITrend: {
        metricKey: getKpiNameByKey(compareKPI),//kpiKey.charAt(0).toUpperCase() + kpiKey.slice(1), // Capitalize the first letter of the KPI key
        metricID: compareKPI,
        selectedPeriod:selectedPeriodComp?selectedPeriodComp:[],
        comparedPeriod: compareKpisComp?compareKpisComp:[]
      },
    },
  };
}

function processComparisonData(apiResponse,kpiKey, startDate, endDate, type,kpiType) {

  return apiResponse[kpiType]?.map(kpi => {

    const actualValue = kpi[kpiKey]?kpi[kpiKey]:0;
    const formattedValue = formatMetricValue(kpiKey, actualValue);

    let duration;
    const durationData = kpi._id.split('_')[0];

    switch (type) {
      case 'daily':
        duration = formatIntDate(durationData);
        break;
      case 'hourly':
        duration = `${durationData}:00`;
        break;
      case 'weekly':
        duration = generateTimelineLabelWeekly(Number(durationData)+1, startDate, endDate);
        break;
      case 'monthly':
        duration = getMonthName(durationData);
        break;
      default:
        duration = durationData;
    }

    return {
      temperature: 28,
      minTemp: 25.83,
      maxTemp: 34.54,
      weather: "Thunderstorm",
      weatherIcon: "wi-thunderstorm",
      formattedMetricValue: formattedValue,
      metricValue: actualValue,
      traffic: "0 ",
      timelineLabel: duration,
      sales: formattedValue,
      conversion: "0 %",
    };
  });
}

function processComparisonData2(apiResponse,kpiKey, startDate, endDate, type,kpiType) {
  
  return apiResponse[kpiType]?.map(kpi => {
    var weeklySort = null;
    const actualValue = kpi[kpiKey]?kpi[kpiKey]:0;
    const formattedValue = formatMetricValue(kpiKey, actualValue);

    let duration;
    let durationData = kpi._id.split('_')[0];

    switch (type) {
      case 'daily':
        duration = formatIntDate(durationData);
        break;
      case 'hourly':
        duration = `${durationData}:00`;
        break;
      case 'weekly':
        if(durationData.length>2)
        {
          const strData = durationData.toString();
          const trimmedData = strData.length > 4 ? strData.slice(0, -4) : "0";
          
          let lastFourDigits = parseInt(durationData.toString().slice(-4));
          durationData = parseInt(trimmedData);
          console.log('lastFourDigits',lastFourDigits);
          // if(kpiType == "kpisCompare")
          // {
          //   lastFourDigits = lastFourDigits+1;
          // }
          if(durationData<=9)
            weeklySort = Number(lastFourDigits+'0'+durationData);
          else
            weeklySort = Number(lastFourDigits+''+durationData);

          duration = generateTimelineLabelWeeklyMultiYear(Number(durationData)+1, lastFourDigits, "kpisCompare");
          console.log('generateTimelineLabelWeekly',duration+'----'+durationData+'-----'+trimmedData);
        }
        else
          duration = generateTimelineLabelWeekly(Number(durationData)+1, startDate, endDate);
        
       
        break;
      case 'monthly':
        duration = getMonthName(durationData);
        break;
      default:
        duration = durationData;
    }

    return {
      temperature: 28,
      minTemp: 25.83,
      maxTemp: 34.54,
      weather: "Thunderstorm",
      weatherIcon: "wi-thunderstorm",
      formattedMetricValue: formattedValue,
      metricValue: actualValue,
      traffic: "0 ",
      timelineLabel: duration,
      sales: formattedValue,
      conversion: "0 %",
      weeklySorter: weeklySort
    };
  });
}

function convertToWeekly(daily,kpiType) {
  const weekly = {};
  var yearAdd  = 0; 
  if(kpiType == 'kpisCompare')
  {yearAdd = 1;}
  daily.forEach(item => {
      const datePart = item._id.split('_')[0];
      const storeCode = item._id.split('_')[1];
      const kpi = Object.keys(item)[1];


      // Convert datePart (YYYYMMDD) to a Date object
      const year = parseInt(datePart.substring(0, 4));
      const month = parseInt(datePart.substring(4, 6)) - 1; // Months are 0-based in JS Date
      const day = parseInt(datePart.substring(6, 8));
      const dateObj = new Date(year, month, day);

      // Get the week number
      const weekNumber = calculateWeekNumber(dateObj);

      // Create a unique key for each store and week
      // const weeklyKey = `${weekNumber}_${storeCode}_${year}`;
      const weeklyKey = `${weekNumber}${year}_${storeCode}`;

      // Sum the kpi data
      if (!weekly[weeklyKey]) {
          weekly[weeklyKey] = { "_id": weeklyKey, [kpi]: 0 };
      }
      weekly[weeklyKey][kpi] += item[''+kpi];
  });

  // Convert the weekly object into an array
  return Object.values(weekly);
}

// const weekly = convertToWeekly(daily);
// console.log(weekly);

function formatMetricValue(kpiKey, actualValue) {
  return `${getSignsLeftSide(kpiKey)}${actualValue.toLocaleString()} ${getSignsRightSide(kpiKey)}`;
}

function generateTimelineLabelWeekly(weekNumber, stDate, edDate) {//getweekly
  weekNumber = weekNumber-1;
  const dateObject = new Date(stDate);
  const year = dateObject.getFullYear();
  
  var weekDaySetting = localStorage.getItem("WeekStartDay");
  if(weekDaySetting == null || weekDaySetting == undefined)
  {
    weekDaySetting = 'monday';
  }

  const getWeekDates = (year, weekNumber) => {
    if(weekDaySetting.toLocaleLowerCase() == 'monday' || weekDaySetting.toLocaleLowerCase() == 'mon')
      {
        const startDate = new Date(year, 0, 1 + (weekNumber - 1) * 7);
        const endDate = new Date(year, 0, 1 + weekNumber * 7 - 1);
        return { startDate, endDate };
      }
      else if(weekDaySetting.toLocaleLowerCase() == 'sunday' || weekDaySetting.toLocaleLowerCase() == 'sun') {
        const startDate = new Date(year, 0, 2 + (weekNumber - 1) * 7);
        const endDate = new Date(year, 0, 2 + weekNumber * 7 - 1);
        return { startDate, endDate };
      }
      else
      {
        const startDate = new Date(year, 0, 1 + (weekNumber - 1) * 7);
        const endDate = new Date(year, 0, 1 + weekNumber * 7 - 1);
        return { startDate, endDate };
      }
  };

  const formatDate = date => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
  };

  const { startDate, endDate } = getWeekDates(year, weekNumber);
  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);
  return `Week ${weekNumber} (${formattedStartDate} - ${formattedEndDate})`;
}
function generateTimelineLabelWeeklyMultiYear(weekNumber, year, kpiType) {//getweekly
  
  if(year<2024){
    debugger;
  }
  weekNumber = weekNumber-1;
 // const dateObject = year;
  //const year = dateObject.getFullYear();
 
  var weekDaySetting = localStorage.getItem("WeekStartDay");
  
  if(weekDaySetting == null || weekDaySetting == undefined ||weekDaySetting == "undefined" )
  {
    weekDaySetting = 'monday';
  }
  
  const getWeekDates = (year, weekNumber) => {
    var addDay = 1;
    const currentYear = new Date().getFullYear();
    if(weekDaySetting.toLocaleLowerCase() == 'monday' || weekDaySetting.toLocaleLowerCase() == 'mon')
      {
        if( kpiType == "kpisCompare" && year<currentYear)
        {
          const currentYear = new Date().getFullYear();
          const diff = currentYear - year;
          addDay = addDay +diff;
        }
        const startDate = new Date(year, 0, addDay + (weekNumber - 1) * 7);
        const endDate = new Date(year, 0, addDay + weekNumber * 7 - 1);
        return { startDate, endDate };
      }
      else if(weekDaySetting.toLocaleLowerCase() == 'sunday' || weekDaySetting.toLocaleLowerCase() == 'sun') {
        if( kpiType == "kpisCompare" && year<currentYear)
          {
            const currentYear = new Date().getFullYear();
            const diff = currentYear - year;
            addDay = addDay +diff;
          }
        addDay++;
        const startDate = new Date(year, 0, addDay + (weekNumber - 1) * 7);
        const endDate = new Date(year, 0, addDay + weekNumber * 7 - 1);
        return { startDate, endDate };
      }
      else
      {
        const startDate = new Date(year, 0, addDay + (weekNumber - 1) * 7);
        const endDate = new Date(year, 0, addDay + weekNumber * 7 - 1);
        return { startDate, endDate };
      }
  };

  //    // if(year<2024)
    // {
    //   addDay++;
    // }

  const formatDate = date => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
  };

  const { startDate, endDate } = getWeekDates(year, weekNumber);
  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);
  return `Week ${weekNumber} (${formattedStartDate} - ${formattedEndDate})`;
}

function getMonthName(monthNumber) {
  const monthNames = [
      "","January", "February", "March",
      "April", "May", "June",
      "July", "August", "September",
      "October", "November", "December"
  ];

  return monthNames[monthNumber];
}

function getNameByStoreCode(data, storeCode) {

  // Loop through the data array to find the object with the matching storeCode
  for (let i = 0; i < data.length; i++) {
    if (data[i].storeCode === storeCode) {
      // Return the name if a match is found
      return data[i].name;
    }
  }
  // Return null if no match is found
  return null;
}
// Location Report
export function convertApiResponseForLocationReport(apiResponse,selectedKpi, storesList,st,ed) {

  const datesDifference = getDateDifference(st,ed);

  // Extract sales data and create a map for quick access
  const kpiMap1 = {};
  const kpiMap = {};
  for (const kpi of apiResponse.kpisCompare) {
    const storeCode = kpi._id;
    if(kpiMap[storeCode])
      kpiMap[storeCode] = kpiMap[storeCode] + kpi[selectedKpi];
    else
      kpiMap[storeCode] = kpi[selectedKpi];
  }

  for (const kpi of apiResponse.kpis) {
    const storeCode = kpi._id;
    if(kpiMap1[storeCode])
    kpiMap1[storeCode] = kpiMap1[storeCode] + kpi[selectedKpi];
    else
    kpiMap1[storeCode] = kpi[selectedKpi];
  }

  var data = [];
  for(const key in kpiMap1)
  {
    const storeCode = key;
    const StoreName = getNameByStoreCode(storesList,storeCode);


    var kpiValue = kpiMap1[key]?kpiMap1[key]:0;
    const previouskpiValue = kpiMap[key]?kpiMap[key]:0;

    var variation;
    if(previouskpiValue == 0 || previouskpiValue == '0' || previouskpiValue == undefined || previouskpiValue == null ||previouskpiValue == '')
      variation = 0;
    else
      variation = ((kpiValue - previouskpiValue) / previouskpiValue) * 100;

      if(selectedKpi == 'ap' || selectedKpi == 'rov' || selectedKpi == 'aip'
      || selectedKpi == 'sl'  || selectedKpi == 'sh' || selectedKpi == 'cs'
      || selectedKpi == 'se')
      {
        variation = Math.round(variation);
        kpiValue = Math.round(Number(kpiValue));
      }
      else if( selectedKpi == 'spt')
      {
        variation = Math.round(variation);
        kpiValue = Math.round(Number(kpiValue/datesDifference));
      }
      else if(selectedKpi == 'tpt')
      {
        variation = Math.round(variation);
        kpiValue = Number(kpiValue/datesDifference).toFixed(2);
      }
      else if(selectedKpi == 'is' || selectedKpi == 'dw')
      {
        variation = Math.round(variation);
      }
      else if(kpiValue.toString().includes('.'))
      {
        kpiValue = Number(kpiValue).toFixed(2);
      }

      var valueWithSigns = '';
      if(selectedKpi == 'dw')
        valueWithSigns = formatDewellTime(kpiValue);
      else
        valueWithSigns = `${getSignsLeftSide(selectedKpi)}${kpiValue.toLocaleString()}${getSignsRightSide(selectedKpi)}`;

      var variations;

      if(selectedKpi == 'ap' || selectedKpi == 'rov' || selectedKpi == 'aip'  || selectedKpi == 'spt'
      || selectedKpi == 'tpt' || selectedKpi == 'sl' || selectedKpi == 'sh' || selectedKpi == 'cs'
      || selectedKpi == 'se' || selectedKpi == 'is')
      {
        if(variation >=0)
          variations = variation + " %";
        else
          variations = variation + "%";
      }
      else
      {
        if(variation ==0 && selectedKpi == 'pb' )
          variations = variation + "%";
        else if(selectedKpi == 'dw')
          variations = variation + "%";
        else
        {
          if(variation >=0)
            variations = variation.toFixed(2) + " %";
          else
            variations = variation.toFixed(2) + "%";
        }
      }

      data.push({
        storeCode: storeCode,
        store: StoreName,
        value: valueWithSigns,
        variation: variations,
      });
  }


  if(selectedKpi == 'ap' || selectedKpi == 'aip'
  || selectedKpi == 'sl'  || selectedKpi == 'cs'
  || selectedKpi == 'se')
  {
    data.sort((a, b) => parseFloat(b.variation.replace('%', '')) - parseFloat(a.variation.replace('%', '')));
    data.sort((b, a) => parseFloat(a.value.replace('¥', '')) - parseFloat(b.value.replace('¥', '')));
  }
  else if(selectedKpi == 'tpt')
  {
    data.sort((a, b) => parseFloat(b.variation.replace('%', '')) - parseFloat(a.variation.replace('%', '')));
    // data.sort((a, b) => parseFloat(b.value) - parseFloat(a.value));
  }
  else if(selectedKpi == 'rov')
  {
    data.sort((a, b) => parseFloat(b.variation.replace('%', '')) - parseFloat(a.variation.replace('%', '')));
  }
  else  if(selectedKpi == 'spt')
  {
    data.sort((a, b) => parseFloat(b.variation.replace('%', '')) - parseFloat(a.variation.replace('%', '')));
  }
  else
  {
    data.sort((a, b) => parseFloat(b.variation.replace('%', '')) - parseFloat(a.variation.replace('%', '')));
  }
    //  data.sort((a, b) => b.variation - a.variation);

  // Add rank to each entry
  data.forEach((entry, index) => {
    entry.rank = index + 1;
    entry.index = index + 1;
  });

  return data;
}


function formatDewellTime(seconds) {

  const minutes = Math.floor(Number(seconds) / 60);
  const remainingSeconds = Number(seconds) % 60;
  // return 'x 時間 y分 z秒';
  const lang = store.getters['user/getLocale'];
  if(lang.toLowerCase() == 'en' || lang.toLowerCase() == 'en-us')
    return `${minutes}min : ${remainingSeconds}s`;
  else
    return `${minutes}分  : ${remainingSeconds}秒`;
}

export function convertApiResponseForComparisonReport(apiResponse: any): ConvertedResponse {// for Comparison Radar chart
  const sumResult = {};

  apiResponse.kpis.forEach((obj) => {
    for (const key in obj) {
      if (key !== "_id") {
        sumResult[key] = (sumResult[key] || 0) + Number(obj[key]);
      }
    }
  });

  //
  // const orderString = "salespersqm,trafficpersqm,sales,traffic,conversion,transactions,avgpurchase,upt,avgitemprice,rov,passersby,staffhours,capturerate,stafflevel,storedwelltime,closingstrength,staffefficiency";
  // const orderedObj = {};
  // orderString.split(',').forEach(key => {
  //     if (sumResult.hasOwnProperty(key)) {
  //         orderedObj[key] = sumResult[key];
  //     }
  // });
  //

  const convertedData: ConvertedResponse = {
      data: []
  };
  const kpis: Record<string, any> = {};
  for (const key in sumResult) {
    if(sumResult[key])
    {
      var kpi = sumResult[key];
      kpi = dataFormatting(key,kpi);
      const variationPercentage = (0).toFixed(2);

      const value = `${getSignsLeftSide(key)}${kpi.toLocaleString()}${getSignsRightSide(key)}`;
      const comparedValue = value;
      kpis[key] = {
          metricKey: getKpiNameByKey(key),//.charAt(0).toUpperCase() + key.slice(1),
          metricKeyShort: getKpiNameByKey(key),//.charAt(0).toUpperCase() + key.slice(1),
          value: value,
          comparedValue: comparedValue,
          variation: (0).toLocaleString(),
          variationPercentage: `${variationPercentage}%`,
          growthIndicator: 0,
          variance: `${variationPercentage}%`,
          metricID: key
      };
    }
    else
    {
      const variationPercentage2 = (0).toFixed(2);
      const valueKpi = '0'.toLocaleString();
      const value2 = `${getSignsLeftSide(key)}${valueKpi}${getSignsRightSide(key)}`;

      kpis[key] = {
          metricKey: getKpiNameByKey(key),
          metricKeyShort: getKpiNameByKey(key),
          value: value2,
          comparedValue: value2,
          variation: (0).toLocaleString(),
          variationPercentage: `${variationPercentage2}%`,
          growthIndicator: 0,
          variance: `${variationPercentage2}%`,
          metricID: key
      };
    }
  }

  convertedData.data.push({
      startDate: apiResponse.startDate,
      endDate: apiResponse.endDate,
      compareStartDate: apiResponse.compareStartDate,
      compareEndDate: apiResponse.compareEndDate,
      kpis
  });

const sortKeys = "spt,tpt,pb,sls,trf,trn,cn,ap,upt,rov,aip,sl,sh,cs,se,cr,dw";

const sortedKeys = sortKeys.split(',').filter((key) => kpis[key] !== undefined);

  // Sorting remaining keys not present in sortKeys
  const remainingKeys = Object.keys(kpis).filter((key) => !sortedKeys.includes(key));
  remainingKeys.sort(); // You can customize the sorting logic if needed

  // Creating a new object with sorted keys
  const sortedKpis: Record<string, any> = {};
  sortedKeys.forEach((key) => {
    sortedKpis[key] = kpis[key];
  });

  // Adding remaining keys to the sorted object
  remainingKeys.forEach((key) => {
    sortedKpis[key] = kpis[key];
  });

  // Updating convertedData with sorted keys
  convertedData.data[0].kpis = sortedKpis;


  return convertedData;
}

function dataFormatting(key,value)
{
  if (key == 'conversion' || key == 'upt' || key == 'trafficpersqm') {
      const valueObj = Number(value);
        value = valueObj.toFixed(2);
  }
  else if (key == 'avgpurchase' || key == 'rov' || key == 'avgitemprice' || key == 'salespersqm'
  || key == 'stafflevel' || key == 'staffhours' || key == 'closingstrength' || key == 'staffefficiency' )
  {
    value = Math.round(Number(value));
  }
  //
  else if (key === 'capturerate')
  {
    value = (value * 100).toFixed(2);
  }
  else if (key === 'storedwelltime')
  {
    value = formatDewellTime(value);
  }
  else
  {
    value = removeTrailingZeros(value);

  }
  return value;
}
function removeTrailingZeros(value) {
  // Convert the value to a string if it's a number
  let stringValue = (typeof value === 'number') ? value.toString() : value;

  // Check if the string ends with '.00'
  if (stringValue.endsWith('.00')) {
      // Remove the trailing '.00'
      stringValue = stringValue.slice(0, -3);
  }

  // Convert the value back to a number if it was originally a number
  return (typeof value === 'number') ? parseFloat(stringValue) : stringValue;
}

function missingKpiKey(kpis)
{
  // if(kpis.includes('spt') || kpis.includes('tpt') )
  if(kpis.includes('spt'))
  {
    if(!kpis.includes('sls'))
      kpis.push('sls');
  }

  if(kpis.includes('tpt'))
  {
    if(!kpis.includes('trf'))
      kpis.push('trf');
  }
  return kpis;
}

export function getSumOfKpi(finalResut)
{
  if(finalResut)
  {
    const inputArray = finalResut;

  const resultArray = [];

  for (let i = 0; i < inputArray.length; i++) {
      const current = inputArray[i];
      let found = false;
      for (let j = 0; j < resultArray.length; j++) {
          const existingEntry = resultArray[j];

          if (existingEntry._id.split('_')[0] === current._id.split('_')[0]) {
              // Sum the key values for the same _id
              for (const key in current) {
                  if (key !== "_id") {
                      existingEntry[key] = (Number(existingEntry[key]) || 0) + Number(current[key]);
                  }
              }
              found = true;
              break;
          }
      }

      if (!found) {
          // If _id doesn't exist, add the current entry to the resultArray
          resultArray.push({ ...current });
      }
    }

    return resultArray;
  }
}
function removeAdditionalKpis(response,kpis)
{
  response.forEach((data) => {
    if(kpis.includes('dw') )
    {
      data.dw = data.dw?data.dw:0;
    }
    if(kpis.includes('st') )
    {
      data.st = data.st?data.st:0;
    }
    if(!kpis.includes('is'))
    delete data.is;
  });
  return response;
}
function missingKpiAllForExport(response,kpis,reportType, numOfDays)
{
    response.forEach((data) => {
      if(kpis.includes('spt') || kpis.includes('tpt') )
      {
      var area = getSqmByStoreCode(data._id.split('_')[0]?data._id.split('_')[0]:0);

      if(kpis.includes('spt'))
        data.spt =Math.round(Number(divideBy2Kpi(data.sls,area,2))/numOfDays);//Math.round(data.sls / area);//numOfDays
      //Number(divideBy2Kpi(data.trn,data.trf,1));

      if(kpis.includes('tpt'))
        data.tpt = Number((Number(divideBy2Kpi(data.trf, area, 3))/numOfDays).toFixed(2));//Math.round((data.trf / area)*100)/100;

        if(reportType != 'summary')
        {
          if(!kpis.includes('sls'))
            delete data.sls;

          if(!kpis.includes('trf'))
            delete data.trf;
        }
      }


      if(kpis.includes('upt'))
      {
        data.upt = Number(data.upt?data.upt:0);//Math.round((data.is / data.trn)*100)/100;
        data.upt =Math.ceil(data.upt * 100) / 100;
        // Math.floor(data.upt *100)/100;
      }
      if(kpis.includes('cn'))
      {
        data.cn = Number((data.cn?data.cn:0)/100);
        data.cn = Math.floor(data.cn *1000)/1000;
      }
      if(kpis.includes('cr'))
      {
         data.cr = Number(data.cr?data.cr:0)/100;
         data.cr = Math.floor(data.cr *1000)/1000;
      }
      if(kpis.includes('dw'))
      {
         data.dw = Math.floor((data.dw?data.dw:0)/60);
      }
      if(kpis.includes('sl'))
      {
         data.sl = Math.round(data.sl);
      }
      if(kpis.includes('se'))
      {
         data.se = Math.round(data.se);
      }
      if(kpis.includes('cs'))
      {
         data.cs = Math.round(data.cs);
      }

      if(reportType != 'summary')
      {
        if(!kpis.includes('sls'))
          delete data.sls;

        if(!kpis.includes('trf'))
          delete data.trf;

        if(!kpis.includes('is'))
          delete data.is;

        if(!kpis.includes('trn'))
        delete data.trn;

        if(!kpis.includes('pb'))
          delete data.pb;

        if(!kpis.includes('sh'))
        delete data.sh;

        if(!kpis.includes('st'))
        delete data.st;


      }
    });

  return response;
}
function missingKpiForExport(response,kpis,reportType,storeCodes)
{
    response.forEach((data) => {
   //   if(reportType == 'daily' || reportType == 'hourly' || reportType =='AllStoresDaily')
      if(reportType == 'hourly' || reportType == 'AllStoresDaily')//getStoreAreaByStoeCodes)
      {
        if(kpis.includes('spt') || kpis.includes('tpt') )
        {
        var area1 = getStoreAreaByStoeCodes(storeCodes);

        if(kpis.includes('spt'))
          data.spt =Math.round(data.sls / area1);

        if(kpis.includes('tpt'))
          data.tpt = Math.round((data.trf / area1)*100)/100;
        }

        // if(!kpis.includes('sls'))
        //   delete data.sls;

        // if(!kpis.includes('trf'))
        //   delete data.trf;
      }
      else if(reportType == 'daily')
      {
        if(kpis.includes('spt') || kpis.includes('tpt') )
        {
        var area = getSqmByStoreCode(data._id.split('_')[1]?data._id.split('_')[1]:0);

        if(kpis.includes('spt'))
          data.spt =Math.round(data.sls / area);

        if(kpis.includes('tpt'))
          data.tpt = Math.round((data.trf / area)*100)/100;
        }
        if(!kpis.includes('sls'))
          delete data.sls;

        if(!kpis.includes('trf'))
          delete data.trf;
      }
      else
      {
        if(kpis.includes('spt') || kpis.includes('tpt') )
        {
        var area2 = getSqmByStoreCode(data._id.split('_')[1]?data._id.split('_')[1]:0);

        if(kpis.includes('spt'))
          data.spt =Math.round(data.sls / area2);

        if(kpis.includes('tpt'))
          data.tpt = Math.round((data.trf / area2)*100)/100;
        }
        if(!kpis.includes('sls'))
          delete data.sls;

        if(!kpis.includes('trf'))
          delete data.trf;
      }

      // if(!kpis.includes('is'))
      // delete data.is;

      if(kpis.includes('upt'))
      {
        data.upt = Number(data.upt?data.upt:0);//Math.round((data.is / data.trn)*100)/100;
      }

      if(kpis.includes('cn'))
      {
        data.cn = Math.floor(data.cn *1000)/1000;
      }
      //commented for hourly
      // if(kpis.includes('cn'))
      // {
      //   data.cn = Number((data.cn?data.cn:0)/100);
      //   data.cn = Math.floor(data.cn *1000)/1000;
      // }
      // if(kpis.includes('cr'))
      // {
      //    data.cr = Number(data.cr?data.cr:0)/100;
      //    data.cr = Math.floor(data.cr *1000)/1000;
      // }


      if(kpis.includes('dw'))
      {
         data.dw = Math.floor((data.dw?data.dw:0)/60);
      }
      if(kpis.includes('sl'))
      {
         data.sl = Math.round(data.sl);
      }
      if(kpis.includes('se'))
      {
         data.se = Math.round(data.se);
      }
      if(kpis.includes('cs'))
      {
         data.cs = Math.round(data.cs);
      }


      if(!kpis.includes('sls'))
      delete data.sls;

    if(!kpis.includes('trf'))
      delete data.trf;

    if(!kpis.includes('is'))
      delete data.is;

    if(!kpis.includes('trn'))
    delete data.trn;

    if(!kpis.includes('pb'))
      delete data.pb;

      if(!kpis.includes('sh'))
      delete data.sh;
      if(!kpis.includes('st'))
      delete data.st;
    });

  return response;
}
function missingKpi(response,kpis,reportType,storeCodes,allStoresArea,page,numOfStores,datesDiff)
{
    response.forEach((data) => {


        if(kpis.includes('spt') || kpis.includes('tpt') )
        {
          var area2 = 1;
          if(page == 'comparison' || page == 'detailed' || page == 'location')
           area2 = allStoresArea;
          else
          area2 = getSqmByStoreCode(data._id.split('_')[1]?data._id.split('_')[1]:0);

        if(kpis.includes('spt'))
          data.spt =Math.round(data.sls / area2);

        if(kpis.includes('tpt'))
          data.tpt = Math.round((data.trf / area2)*100)/100;

        }

      if(kpis.includes('upt'))
      {
         data.upt = Number((Math.round((data.is / data.trn)*100)/100).toFixed(2));
      }
      if(kpis.includes('cn'))
      {
        data.cn = Number(divideBy2Kpi(data.trn,data.trf,1));
      }
      if(kpis.includes('ap'))
      {
        data.ap = divideBy2Kpi(data.sls,data.trn,2);
      }
      if(kpis.includes('cr'))
      {
        ////capturerate = traffic/passersby
         data.cr = Number(divideBy2Kpi(data.trf,data.pb,1));
      }
    //  data.rov = data.rov/6;
      if(kpis.includes('rov'))
      {
        //Return on Visit = Sales/Traffic
        data.rov = divideBy2Kpi(data.sls,data.trf,2);
      }
      if(kpis.includes('aip'))
      {
        ////capturerate = traffic/passersby
         data.aip = divideBy2Kpi(data.sls,data.is,2);///6;
      }

      if(kpis.includes('sh'))
      {
        ////capturerate = traffic/passersby
         data.sh = divideBy2Kpi(data.sh ,numOfStores,2);
      }

      if(kpis.includes('dw'))
      {
         data.dw = Math.floor(((data.dw?data.dw:0))/2);
         data.dw = formatDuration(data.dw);
      }
      if(kpis.includes('sl'))
      {
         data.sl = Math.round(data.sl/numOfStores);//number of stores
      }
      if(kpis.includes('se'))
      {
         data.se = Math.round(data.se/numOfStores);
      }
      if(kpis.includes('cs'))
      {
         data.cs = Math.round(data.cs/numOfStores);
      }


      if(!kpis.includes('sls'))
        delete data.sls;

      if(!kpis.includes('trf'))
        delete data.trf;

      if(!kpis.includes('is'))
        delete data.is;

      if(!kpis.includes('trn'))
      delete data.trn;

      if(!kpis.includes('pb'))
        delete data.pb;

        if(!kpis.includes('sh'))
        delete data.sh;
        if(!kpis.includes('st'))
        delete data.st;
    });

  return response;
}
function formatDuration(seconds) {
  if (isNaN(seconds) || seconds < 0) {
      return "0min : 0s";
  }

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const lang = store.getters['user/getLocale'];
  if(lang.toLowerCase() == 'en' || lang.toLowerCase() == 'en-us')
    return `${minutes}min : ${remainingSeconds}s`;
  else
    return `${minutes}分  : ${remainingSeconds}秒`;

 // const formattedDuration = `${minutes}min : ${remainingSeconds}s`;

//  return formattedDuration;
}
function divideBy2Kpi(val1, val2, bit) {
  val1 = Number(val1) || 0; // Set val1 to 0 if it's null or undefined
  val2 = Number(val2) || 0; // Set val2 to 0 if it's null or undefined
  const divisor = val2 !== 0 ? val2 : 1; // Avoid division by zero

  if(val2 == 0)
    val1 = 0;

    if (bit === 0) {
    const val = val1 / divisor;
    return Math.round(val * 100) / 100;
  } else if (bit === 1) {
    const val = (val1 / divisor) * 100;
    return val.toFixed(2);
  } else if (bit === 3) {//tpt
    const val = Math.round((val1 / divisor) * 100)/100;
    return val.toFixed(2);
  } else {
    const val = Math.round(val1 / divisor);
    return val;
  }
}
function getAllStoresArea(storeCodeList){
  var totalArea = 0;
  if(storeCodeList)
  {
    for(var i=0;i<storeCodeList.length;i++)
    {
      totalArea = totalArea + Number(getSqmByStoreCode(storeCodeList[i]));
    }
  }
return totalArea;
}
export function getSqmByStoreCode(storeCode) {
  const storeListForArea = store.getters['user/getStoreListForArea'];
  return storeCode in storeListForArea ? storeListForArea[storeCode] : 1;
}
export function getStoreAreaByStoeCodes(storeCodeList){

  var sumOfArea =0;
  for(const item in storeCodeList)
    sumOfArea = sumOfArea + getSqmByStoreCode(storeCodeList[item]);
  return sumOfArea;

}
function getSumOfKpiByStoreCode(finalResut)
{

  if(finalResut)
  {
    const inputArray = finalResut;

  const resultArray = [];

  for (let i = 0; i < inputArray.length; i++) {
      const current = inputArray[i];
      let found = false;
      for (let j = 0; j < resultArray.length; j++) {
          const existingEntry = resultArray[j];

          if (existingEntry._id.split('_')[1] === current._id.split('_')[1]) {
              // Sum the key values for the same _id
              for (const key in current) {
                  if (key !== "_id") {
                      existingEntry[key] = (Number(existingEntry[key]) || 0) + Number(current[key]);
                  }
              }
              found = true;
              break;
          }
      }

      if (!found) {
          // If _id doesn't exist, add the current entry to the resultArray
          resultArray.push({ ...current });
      }
    }
    return resultArray;
  }
}
function extractNumericValue(inputString) {
  // Remove non-numeric characters (excluding '.' for decimal)
  const numericString = inputString.replace(/[^0-9.]/g, '');

  // Convert the numeric string to a number
  const numericValue = parseFloat(numericString);

  return isNaN(numericValue) ? null : numericValue;
}
export function convertApiResponseDistribution(apiResponse: any) {
  const selectedKPIValues = [];
  const comparedKPIValues = [];
  for(const key in apiResponse)
  {
    const metricID = apiResponse[key].metricID;
    const metricKey = getKpiNameByKey(metricID);
    var formattedValue = apiResponse[key].value;
    var compformattedValue = apiResponse[key].comparedValue;
    const actualValue = extractNumericValue(apiResponse[key].value);
    const compValue =extractNumericValue(apiResponse[key].comparedValue);

    if(metricID == 'spt')
      {
       formattedValue = addSign('sls', actualValue.toLocaleString());
       compformattedValue = addSign('sls', compValue.toLocaleString());
      }
    const obj =
    {
      metricKey: metricKey,
      value: actualValue,
      formattedValue: formattedValue,
      metricID: metricID,
    }
    const objComp =
    {
      metricKey: metricKey,
      value: compValue,
      formattedValue: compformattedValue,
      metricID: metricID,
    }
    selectedKPIValues.push(obj);
    comparedKPIValues.push(objComp)

  }

  return {
      data: {
          selectedKPIValues,
          comparedKPIValues
      },
  };
}

