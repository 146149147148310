import {
  APIAuthenticate,
  APIReAuthenticate,
  APIFetchProfile,
  AccessTokenValidation
} from '@/api/auth';
import router from '@/router';
import config from '@/config';
import store from '@/store'

import clientManager from '@/util/client';
import localForage from 'localforage';
import { initialDefaultSettings } from '@/models/onboarding/DefaultSettings';
// import { kpiListGet2 } from '@/util/mockData/kpiMapping';
import { Cookies } from 'quasar';
import { PostRequest } from '@/util/http';
// import { APIFetchStores } from '@/api/store';
import { APIFetchStoresFida } from '@/api/storeFida';

import { convertResponse } from '@/api/kpiApiResponceConversion';
import {
  APIAuthenticateSSO,
  APIRegisterEmailVerificationSSO,
  APIRegisterCodeVerificationSSO,
  APICodeVerificationSSO,
  APIRegisterNormal,
  APIUpdateProfileSSO,
  APIChangePasswordSSO,
  APIUpdatePasswordSSO,
  APIInviteCodeVerificationSSO,
  APIRegisterInvited,
  APIRegisterInviteUserSSO,
  APISignoutSSO,
  APIUpdateLangSSO
} from '@/api/authSSO';
import users from '@/services/users';
import { SSOPostRequest, SSOGetRequest2 } from '@/util/kpis';
import axios from 'axios';
import { debug } from 'console';
import Vue from "vue";
import { setUsersParamsValues } from '@/util/userParams';
import VueI18n from "vue-i18n";
// const t = useI18n();
const client = clientManager.testingClient;
const initialState = {
  session: {},
  sessionClient: {},
  clientProfile: {},
  profile: {},
  userPermissions: {},
  role: {},
  // kpiPreferences: kpiListGet2,
  defaultSettings: initialDefaultSettings(),
  locale: navigator.language.includes('en') ? 'en' : 'ja',
  startOfWeek: 2,
  coronaBannerReadStatus: false,
  clientId: "aye9-qt1cj-abhrhzy7-m2tup-6x92kc5688",
  clientSecret: "fp329-polk80s-ye04p1yy-45hx874z06",
  translate:null,
  translateKpi: null,
  startWeek: "",
  storesCodeName: {},
  storesGroup: {},
  storesCodeNameList: null,
  storeListForArea: {},
  storesGroupFida: {},
  storeList: null
}; // initialState

export default {
  state: initialState, // state
  mutations: {
    setTranslations(state, payload) {
      state.translate = payload;
    },
    setTranslationsWhole(state, payload) {
      state.translateWhole = payload;
    },
    setTranslationsKpi(state, payload) {
      state.translateKpi = payload;
    },
    setUserPermissions(state, permissions) {
      state.userPermissions = permissions;
    },
    setStartWeek(state, payload) {
      state.startWeek = payload;
    },
    setSession(state, { session }) {
      if (session && Object.keys(session).length) {
        state.session = session;
      } else {
        state.session = {};
      }
    },
    setClearSession(state) {
      state.session.value = null;
      state.session.access_token = null;
    }, // setSession
    setDemoSession(state, { session }) {
      if (session && Object.keys(session).length) {
        state.session = session;
      }
    }, // setSession
    setStartOfWeek(state, payload) {
      state.startOfWeek = payload;
    },
    setClientSession(state, { session }) {
      if (session && Object.keys(session).length) {
        state.sessionClient = session;
      }
    }, // setSession
    clearClientSession(state, { session }) {
      state.sessionClient = {};
    }, // setSession
    setBearerToken(state, { token }) {
      client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }, // setBearerToken
    async setLocale(state, { locale, denyRefresh }) {
      console.log(state, Vue.prototype.$i18n, this)
      if(locale.indexOf('en') > -1)
      {
        locale = 'en';
      }
      let isUpdated = state.locale != locale;
      state.locale = locale;
      import(
        /* webpackInclude: /(ja|en-us)\.js$/ */
        "quasar/lang/" + locale
      ).then((lang) => {
        Vue.prototype.$q.lang.set(lang.default);
      });
      APIUpdateLangSSO({ lang: locale });
      await this.dispatch('fetchKpiPreferences')
      if(isUpdated && !denyRefresh) {
        (router as any).go(0);
      }
    }, // setLocale
    setProfile(state, { profile }) {
      state.profile = profile;
    },
    setRole(state, { role }) {
      state.role = role;
    },
    setClientProfile(state, { profile }) {
      state.clientProfile = profile;
    }, // setProfile
    setDefaultSettings(state, payload) {
      state.defaultSettings = payload;
    },
    //kpiListGet
    setKpiPreferences(state, payload) {
      state.kpiPreferences = payload;
    }, // setProfile
    setKpiPreferencesNew(state, payload) {
      state.kpiList = payload;
    },
    setKpisLabelValue(state, payload) {
      state.kpiLabelValue = payload;
    },
    setOccupancyKpis(state, payload) {
      state.occupancyKpis = payload;
    },
    setKpis(state, payload) {
      state.kpis = payload;
    },
    setKpiPreferencesja(state, payload) {
      state.kpiListja = payload;
    },
    setStoresList(state, payload) {
      state.stores = payload;
    },setStoreCodeName(state, payload) {
      state.storesCodeName = payload;
    },
    setStoresGroup(state, payload) {
      state.storesGroup = payload;
    },
    setStoresGroupFida(state, payload) {
      state.storesGroupFida = payload;
    },
    setStoresCodeNameList(state, payload) {
      state.storesCodeNameList = payload;
    },
    setStoreListForArea(state,payload) {
      state.storeListForArea = payload;
    },
    setStoreList(state, storeList) {
      state.storeList = storeList;
    }
    // ,
    // setStoresTimings(state,payload) {
    //   state.storesTimings = payload;
    // }
  }, // mutations
  getters: {
    getSession: state => {
      return state.session;
    }, // getSession
    getProfile: state => {
      return state.profile;
    }, // getSession
    getDefaultSettings: state => {
      return state.defaultSettings;
    },//getsettings
    getClientSession: state => {
      return state.sessionClient;
    }, // getSession
    getToken: state => {
      return state.session.access_token;
    }, // getToken
    getClientToken: state => {
      return state.sessionClient.value;
    }, // getClientToken
    getPermissions: state => {
      return state.userPermissions;
    }, // getPermissions
    getRole: state => {
      return state.role;
    }, // getRole
    getRefreshToken: state => {
      if (state.session && state.session.refreshToken) {
        return state.session.refreshToken.value;
      }
      return '';
    }, // getRefreshToken
    getLocale: state => {
      return state.locale ? state.locale : navigator.language;
    }, // getLocale
    isAuthenticated: state => {
      return !!state.session.access_token;
    }, // isAuthenticated
    getStoreNameList: state => {
      const stores = state.stores.stores;
      return stores?.map((store) => { return store.name })
    },
    getStoresCodeName: state => {
      return state.storesCodeName;
     // return state.locale ? state.locale : navigator.language;
    },

    getStoreGroups: state => {
      return state.storesGroup;
     // return state.locale ? state.locale : navigator.language;
     },
     getStoreGroupsFida: state => {
         return state.storesGroupFida;
       // return state.locale ? state.locale : navigator.language;
     },
     getStoresCodeNameList: state =>{
       return state.storesCodeNameList;
     },
     getStoreListForArea: state =>{
       return state.storeListForArea;
     },
     getKpisLabelValue: state => {
       return state.kpiLabelValue;
     },
    getStoreCodeList: state => {
      const stores = state.stores.stores;
      return stores?.map((store) => { return store.storeCode })
    }

  }, // getters
  actions: {
    async clearSession({ commit }) {
      localStorage.clear();
      await localForage.clear();
      sessionStorage.clear();
      const cookies = Cookies.getAll();
      Object.keys(cookies).map(cookie => {
        Cookies.remove(cookie, { path: '/', domain: '.flow-insight.com' });
      });
      commit('setClearSession', null);
      commit('setProfile', { profile: {} });
      commit('setUserPermissions', {});
      commit('setClientSession', {});
      commit('setBearerToken', { token: null });
      commit('setClientProfile', { profile: null });
      commit('setRole', { role: {} });
    }, // clearSession
    async authenticateDemo({ commit, getters }) {
      const res2 = await APIAuthenticate({
        username: 'demo@flow-solutions.com',
        password: 'Insight2020'
      });
      commit('setDemoSession', { session: res2 });
      // commit('setClientSession', { session: getters.getClientSession });
      // commit('setBearerToken', { token: getters.getToken });
      return res2;
    },
    async loginUserAndGetDetails({commit, getters, dispatch}, {username, password, userlang, accessToken}) {
      if(!accessToken) {
        const {
          response: { authentication },
          status
        } = await APIAuthenticateSSO({
          username: username,
          password: password
        });
        if (status?.toString() !== '200') {
          throw new Error();
        }

        accessToken = authentication.access_token
      }
      await commit('setClientSession', { session: {access_token: accessToken} });
      await commit('setSession', { session: {access_token: accessToken} });
      const user = await users.getUserDetails()
      const {email, profile, role, tenant, ...rest} = user.data.response.userDetails;
      return {email, profile, role, tenant, rest, token: accessToken}
    },
    async loginUser({ commit, getters, dispatch }, {username, password, userlang, accessToken}) {

      const {email, profile, role, tenant, rest, token} = await dispatch('loginUserAndGetDetails', {username, password, userlang, accessToken});
 
      let user = {response: {userDetails: {email, profile, role, tenant, rest, authentication: {access_token: token}}}}
      accessToken = token;
  
      const isAdmin =  role.title.includes('FlowTenantAdmin') || false;
     // alert(isAdmin);
      localStorage.setItem('fidaMigrationCheck',"true");//rest?.migrated);//"true"
      localStorage.setItem('tenentID',tenant._id);
      localStorage.setItem('isAdmin',isAdmin);

      commit('setProfile', {
        profile: { ...profile, tenant, ...rest, email }
      });
      commit('setRole', {
        role: {
          roleId: role._id,
          title: role.title,
          isAdmin: role.title.includes('FlowTenantAdmin') || false,
          description: role.description
        }
      });
      commit('setClientProfile', {
        profile: { ...profile, ...rest, email }
      });
      commit('setBearerToken', {
        token: accessToken
      });

      const permissions = {};
      role.permissions.map(p => {
        permissions[p.title] = p;
        return p;
      });
      commit('setUserPermissions', permissions);

      await dispatch('fetchStores');
      const userDetails = await Vue.prototype.$repo.settings.getDefaultSettings();

      const usrRoleSetting = user.response.userDetails.role.config;
      const usrTenentSetting = user.response.userDetails.tenant.config;

      if (usrRoleSetting != null && usrRoleSetting != 'undefined') {
        userDetails.locationFilter = usrRoleSetting.allowLocationFilter;
        userDetails.headquartersComparePeriods =
          usrRoleSetting.comparePeriod;
        userDetails.headquartersSelectPeriods =
          usrRoleSetting.selectedPeriod;
      } else {
        userDetails.locationFilter = usrTenentSetting.allowLocationFilter;
        userDetails.headquartersComparePeriods =
          usrTenentSetting.comparePeriod;
        userDetails.headquartersSelectPeriods =
          usrTenentSetting.selectedPeriod;
      }
      commit('setDefaultSettings', { ...userDetails, user });

      Vue.prototype.$client.setSession({
        tenant: tenant?.intercom?.companyName
      });
      await Vue.prototype.$repo.session.put(tenant?.intercom?.companyName);
      //,tenant.intercom.companyId,tenant.intercom.addonsIds,tenant.intercom.plansIds
      //
      localStorage.setItem('userID', profile?._id);
      const accountType = rest?.plans[0]?.code?.toString()?.includes('Demo');
        
        
      localStorage.setItem('accountType', accountType);
      localStorage.setItem('isDemoAccount', profile?._id);
      console.log('userDetails',userDetails);
      console.log('userDetails',userDetails.storeComparePeriods);
      localStorage.setItem('comparisonPeriodForTree', userDetails.storeComparePeriods);
      //for chat
      localStorage.setItem('usernameChat', email);
      await setUsersParamsValues();
      await dispatch('fetchKpiPreferences')

      const userData = rest;
      Vue.prototype?.$mixpanel?.people.set({
        $distinct_id: userData._id,
        $created: userData?.cDate?.timeStamp,
        $username: userData?.username,
        $role: userData?.role?.title,
        $email_verification: userData?.emailVerification?.verified,
        $tenant_id: tenant?._id,
        $tenant_name: tenant?.domain,
        $SSO_ID: userData?._id,
        $plan_code: userData?.plans[0]?.code,
        $plan_name: userData?.plans[0]?.code
      });
      Vue.prototype?.$mixpanel?.identify(userData._id);
      Vue.prototype?.$mixpanel?.track('sign in');
      Vue.prototype?.$mixpanel?.time_event('sign out');

      return tenant;
      

    },
    async authenticateSSO({ commit, getters }, { username, password, userlang }) {
      const {
        response: { authentication, email, profile, role, tenant, ...rest },
        status
      } = await APIAuthenticateSSO({
        username: username,
        password: password
      });
      if (status?.toString() !== '200') {
        throw new Error();
      }
      localStorage.setItem('fidaMigrationCheck',"true");//rest?.migrated);//"true"
      localStorage.setItem('tenentID',tenant._id);

      commit('setClientSession', { session: authentication });
      commit('setSession', { session: getters.getClientSession });
      commit('setProfile', {
        profile: { ...profile, tenant, ...rest, email }
      });
      commit('setRole', {
        role: {
          roleId: role._id,
          title: role.title,
          isAdmin: role.title.includes('FlowTenantAdmin') || false,
          description: role.description
        }
      });
      commit('setClientProfile', {
        profile: { ...profile, ...rest, email }
      });
      commit('setBearerToken', {
        token: authentication.access_token
      });

      const permissions = {};
      role.permissions.map(p => {
        permissions[p.title] = p;
        return p;
      });
      commit('setUserPermissions', permissions);
      return tenant;
    }, // authenticateSSO

    async authenticateInvite({ commit, getters }, { code, password }) {
      const {
        response: { authentication, email, profile, tenant, role, ...rest },
        status
      } = await APIRegisterInviteUserSSO({
        code,
        password
      });
      if (status?.toString() !== '200') {
        throw new Error();
      }
      commit('setClientSession', { session: authentication });
      commit('setRole', {
        role: {
          roleId: role._id,
          title: role.title,
          isAdmin: role.title.includes('FlowTenantAdmin') || false,
          description: role.description
        }
      });
      commit('setSession', { session: getters.getClientSession });
      commit('setProfile', {
        profile: { ...profile, tenant, ...rest, email }
      });
      commit('setClientProfile', {
        profile: { ...profile, ...rest, email }
      });
      commit('setBearerToken', {
        token: authentication.access_token
      });
      const permissions = {};
      role.permissions.map(p => {
        permissions[p.title] = p;
        return p;
      });
      commit('setUserPermissions', permissions);
      return tenant;
    }, // authenticateInviteSSO

    async registerEmailVerificationSSO({ commit, getters }, { email }) {
      try {
        const { response, status } = await APIRegisterEmailVerificationSSO({
          email
        });
        return response;
      } catch (err) {
        throw err;
      }
    }, // registerEmailVerificationSSO

    async updateProfileSSO({ commit, getters }, { firstName, lastName, username }) {
      const { response, status } = await APIUpdateProfileSSO({
        data: { firstName, lastName, username }
      });
      if (status?.toString() !== '200') {
        throw new Error();
      }
      return response;
    }, // updateProfileSSO
    //APIUpdatePasswordSSO
    async updatePasswordSSO(
      { commit, getters },
      { _id, newPassword, newConfirmPassword }
    ) {
      const { response, status } = await APIUpdatePasswordSSO({
        data: { _id, newPassword, newConfirmPassword }
      });
      if (status?.toString() !== '200') {
        throw new Error();
      }
      return response;
    },

    async changePasswordSSO(
      { commit, getters },
      { oldPassword, newPassword, newConfirmPassword }
    ) {
      const { response, status } = await APIChangePasswordSSO({
        data: { oldPassword, newPassword, newConfirmPassword }
      });
      if (status?.toString() !== '200') {
        throw new Error();
      }
      return response;
    }, // changePasswordSSO

    async registerCodeVerificationSSO({ commit, getters }, { code }) {
      const { response, status } = await APIRegisterCodeVerificationSSO({
        code
      });
      if (status?.toString() !== '200') {
        throw new Error();
      }
      return response;
    }, // registerCodeVerificationSSO

    async inviteCodeVerificationSSO({ commit, getters }, { code }) {
      const { response, status } = await APIInviteCodeVerificationSSO({
        code
      });
      if (status?.toString() !== '200') {
        throw new Error();
      }
      return response;
    }, // inviteCodeVerificationSSO

    async codeVerificationSSO(
      { commit, getters },
      { code, password, confirmPassword }
    ) {
      const { response, status } = await APICodeVerificationSSO({
        code
      });
      if (status?.toString() !== '200') {
        throw new Error();
      }
      return response;
    }, // registerCodeVerificationSSO

    async registerNormal({ commit, getters }, { data, invited }) {
      try {
        var code = data.code;
        var password = data.password;
        const {

          response: { authentication, email, profile, role, tenant, ...rest },
          status
        } = invited
            ? await APIRegisterInviteUserSSO({ code, password })
            // ? await APIRegisterInvited({ data })
            : await APIRegisterNormal({ data });
        commit('setClientSession', { session: authentication });
        commit('setSession', { session: getters.getClientSession });
        commit('setProfile', {
          profile: { ...profile, tenant, ...rest, email }
        });
        commit('setRole', {
          role: {
            roleId: role._id,
            title: role.title,
            isAdmin: role.title.includes('FlowTenantAdmin') || false,
            description: role.description
          }
        });
        commit('setClientProfile', {
          profile: { ...profile, ...rest, email }
        });
        commit('setBearerToken', {
          token: authentication.access_token
        });
        const permissions = {};
        role.permissions.map(p => {
          permissions[p.title] = p;
          return p;
        });
        commit('setUserPermissions', permissions);
        return tenant;
      } catch (err) {
        throw err;
      }
    }, // registerNormal

    async authenticate({ commit, getters }, { username, password }) {
      let userEmail = username;
      try {
        const res: any = await APIAuthenticate({
          username: username,
          password: password
        });
        if (res?.status?.toString() === 'ERROR') {
          throw new Error();
        }
        if (res.whiteListEmail) {
          const whiteListEmail = JSON.parse(res.whiteListEmail);
          userEmail = whiteListEmail.whitelistedEmail;
        }
        commit('setClientSession', { session: res });
        return userEmail;
      } catch (err) {
        throw new Error();
      }
    }, // authenticate
    setParams() { },
    reAuthenticate: async function ({ commit, getters }) {
      const refresh_token = getters.getRefreshToken;

      try {
        if (!refresh_token) {
          throw 'No refresh token available!';
        }

        const res = await APIReAuthenticate({ refresh_token });

        commit('setSession', { session: res });
        commit('setBearerToken', { token: getters.getToken });
        return res;
      } catch (error) {
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: error
        });
      }
    }, // reAuthenticate
    async cleanupAfterLogout({dispatch, commit, getters}) {
      if((router as any)?.history?.current?.name != "auth:login") {
        let locale = getters.getLocale;
        let status = localStorage.getItem('status');
        await dispatch('clearSession');
        await store.dispatch('tenant/reset');
        Vue.prototype?.$intercom?.shutdown?.();
        Vue.prototype?.$mixpanel?.track('sign out');
        Vue.prototype?.$mixpanel?.reset();
        localStorage.clear();
        await commit('setLocale', { locale });
        localStorage.setItem('status', status);
        config.setConfig();
        router.push({ name: 'auth:login' }).catch(_ => {});
      }
    },
    async logout({ commit, dispatch }, param) {
      await APISignoutSSO(param);
      await dispatch('cleanupAfterLogout');
    },
    async fetchProfile({ commit, getters }) {
      try {
        const { data } = await axios.post(
          'rest/userProfile',
          {},
          {
            headers: {
              Authorization: `Bearer ${getters.getToken}`
            }
          })
        commit('setProfile', { profile: data });
        return data;
      } catch (error) {
        console.log('fetchProfile(fail): ', error);
      }
    }, // reAuthenticate
    async fetchClientProfile({ commit, getters }) {
      try {
        const { data } = await PostRequest(
          '/rest/userprofile',
          {},
          {
            headers: {
              Authorization: `Bearer ${getters.getClientToken}`
            }
          }
        );
        commit('setClientProfile', { profile: data });
        return data;
      } catch (error) {
        console.log('fetchProfile(fail): ', error);
      }
    }, // reAuthenticate
    async fetchKpiPreferences({ commit, getters, rootState }) {
      try {

        if(!getters.getToken) {
          return;
        }
      const apiResponse: any = await SSOGetRequest2(
        '/api/getTenantPreferences?client_id='+'aye9-qt1cj-abhrhzy7-m2tup-6x92kc5688'+'&client_secret=fp329-polk80s-ye04p1yy-45hx874z06',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getters.getToken}`
          }
        }
      );
//getTenantPreferences
      const secondResponse = convertResponse(apiResponse.data.response.kpiDefinations, getters.getLocale);
      const kpis = secondResponse;

      let kpiList = Object.values(kpis).map((kpi: any) => {
        return kpi;
      });

        const occupancyKpiOptions = [
          'avgoccupancy',
          'maxoccupancy',
          'minoccupancy',
          'avgoccupancy%'
        ];
        const occupancyKpis = kpiList
          .filter(k => occupancyKpiOptions.includes(k.metricKey))
          .map(k => {
            switch (k.metricKey) {
              case 'avgoccupancy':
                k.metricKey = 'avgOccupancy';
                break;

              case 'maxoccupancy':
                k.metricKey = 'maxOccupancy';
                break;

              case 'minoccupancy':
                k.metricKey = 'minOccupancy';
                break;

              case 'avgoccupancy%':
                k.metricKey = 'avgOccupancyPercentage';
                break;
            }
            return k;
          });
        kpiList = kpiList
          .filter(kpi => {
            return kpi.visibility === 1;
          })
          .sort((a, b) => {
            return a.order - b.order;
          });
        kpiList.map(singleKpi => {
          if (singleKpi.title == "Sales Per Sqm") {
            singleKpi.title = "Sales Per Tsubo";
          }
          if (singleKpi.title == "Traffic Per Sqm") {
            singleKpi.title = "Traffic Per Tsubo";
          }
          return singleKpi;
        });

        kpiList = kpiList.map(singleKpi => {
          if (singleKpi.metricKey === "transactions") {
            const { videoUrl, ...rest } = singleKpi;
            return rest;
          }
          return singleKpi;
        });

        const kpiLabelValue = kpiList.map(singleKpi => {
          return {
            label: singleKpi.title,
            value: singleKpi.metricKey
          };
        });
        // kpiList?.splice(1,0,
        //   {
        //     "order": 2,
        //     "visibility": 2,
        //     "title": rootState.user.translate.target_achievement_rate,
        //     explanation: rootState.user.translate.target_achievement_rate_explanation,
        //     "unit": "%",
        //     "videoUrl": "",
        //     "metricKey": "targetAchive"
        // })

        const kpiPreferences = kpiList;
        commit('setOccupancyKpis', occupancyKpis);
        commit('setKpiPreferences', kpiPreferences);
        commit('setKpiPreferencesNew', kpiList);
        commit('setKpis', `kpis`);
        commit('setKpisLabelValue', kpiLabelValue);



        const Responseja = convertResponse(apiResponse.data.response.kpiDefinations, 'ja');

        let kpiListja = Object.values(Responseja).map((kpi: any) => {
          return kpi;
        });

        kpiListja = kpiListja
          .filter(kpi => {
            return kpi.visibility === 1;
          })
          .sort((a, b) => {
            return a.order - b.order;
          });
        commit('setKpiPreferencesja', kpiListja);
      } catch (error) {
        // console.log(error);
      }
    }, // reAuthenticate
    async fetchStoreList({ commit }) { //for pulse
      const storeList:any = await APIFetchStoresFida('storesOnly');
      const sortedStores = storeList.stores?.sort((a, b) => {
        return a.storeCode - b.storeCode
      })
      const storeOption = sortedStores.map(singleStore => {
        return {
          label: singleStore.storeCode + ' -  ' + singleStore.name,
          value: singleStore.storeCode,
          cityId: singleStore.cityId,
        };
      });
      commit('setStoreList', storeOption);
      return storeOption;
    },
    async fetchStores({ commit, state }) {
      const pathname = window.location.pathname;
      if (state?.stores?.stores?.length > 0 && pathname!="/" && pathname!="/stores") {
        return state.stores; // Return existing data
      }

      const res: any = await APIFetchStoresFida('all');

      
      // const res = await APIFetchStores();
      const stores = res.data.stores.map(x => x.storeCode);
      const storesCodeName = res.data.stores;
      const storeListForArea = {};

      for (const item of storesCodeName) {
        storeListForArea[item.storeCode] = item.area ? parseFloat(item.area) : 1;
      }
      commit('setStoreListForArea', storeListForArea);


      const storesGroup = res.data;

      commit('setStoresList', stores);
      commit('setStoresCodeNameList', res.data.stores);
      commit('setStoreCodeName', storesCodeName);
      commit('setStoresGroup', storesGroup);
      // commit('setStoresTimings', storesTimings);

      return res;
    },

    async fetchStoresFida({ commit, state }) {
      // const pathname = window.location.pathname;

      //   if (state?.stores?.storeListForArea?.length > 0 && pathname!="/") {
      //     return state.storeListForArea; // Return existing data
      //   }
      const res3:any = await APIFetchStoresFida("all");
      const res2 = res3.data;
      const combinedStores = res2.stores;

      // Use a Set to store distinct values based on storeNumber

      const storeListForArea = {};

      for (const item of combinedStores) {
        storeListForArea[item.storeCode] = item.area ? parseFloat(item.area) : 1;
      }
      const storesGroupFida = res3.data;

      commit('setStoresGroupFida', storesGroupFida);
      commit('setStoreListForArea', storeListForArea);
      return res3;
    }
  }
};
