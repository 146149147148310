
export default {
  props: {
    video: {
      type: String,
      required: false,
      default: ''
    },
    elevated: {
      type: Boolean,
      required: false,
      default: false
    },

    title: {
      type: String,
      required: false,
      default: ''
    },
    tooltip: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: String,
      required: false,
      default: ''
    },
    variationPercentage: {
      type: String,
      required: false,
      default: ''
    },
    growthIndicator: {
      type: Number,
      required: false,
      default: 0
    },
    comparedValue: {
      type: String,
      required: false,
      default: ''
    },
    variance: {
      type: String,
      required: false,
      default: ''
    },
    metricKey: {
      type: String,
      required: false,
      default: ''
    },
    compareDemographicsGroup: {
      type: String,
      required: false,
      default: ''
    },
    currentDemographicGroup: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    getVariationPercentage() {
      return parseFloat(this.variationPercentage);
    },
    getVariance() {
      return this.variance.replace('', '');
    },
    demoGroupFontSize() {
      if (this.compareDemographicsGroup.length > 35) {
        return 13;
      } else if (this.compareDemographicsGroup.length > 25) {
        return 16;
      } else if (this.compareDemographicsGroup.length > 15) {
        return 20;
      } else if (this.compareDemographicsGroup.length > 15) {
        return 25;
      } else {
        return 30;
      }
    }
  }
};
