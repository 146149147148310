
import { mapState } from 'vuex';
import { getSignsLeftSide,getSignsRightSide } from '@/util/replaceFidaKpi';
export default {
props: {
  flat: {
    type: Boolean
  }
},
data(){
  return {
    fida: 'true'
  }
},
computed: {
  ...mapState('overview', {
    data: 'kpis'
  }),
  ...mapState('traffic', {
    totalTrafficCount: 'totalTrafficCount',
    totalComparedTrafficCount: 'totalComparedTrafficCount',
    varianceTotal: 'varianceTotal'
  }),
  getFormated(){
    return getSignsLeftSide('trf') + this.totalTrafficCount.toLocaleString() + getSignsRightSide('trf')
  },
    getVariationPercentage() {
      return parseFloat(this.data.traffic.variationPercentage);
    },
    getVariance() {

      return this.data?.traffic?.variance?.replace('', '');
    },
    getVarianceClass(){
      if(this.totalTrafficCount > this.totalComparedTrafficCount)
        return false;
      else
       return true;
    }
}
}; // export default
